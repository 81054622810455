import StudentDashboard from "../components/Views/Student/Dashboard.vue";

import LecturerDashboard from "../components/Views/Lecturer/Home/Dashboard.vue";

//department's components
import DepartmentDashboard from "../components/Views/Department/Dashboard.vue";

//faculty components
import FacultyDashboard from "../components/Views/Faculty/Dashboard.vue";

//academic level adviser's components
import AdviserDashboard from "../components/Views/AcademicLevelAdviser/Dashboard.vue";

//academic level adviser's components
import ExamOfficerDashboard from "../components/Views/Department/ExamOffice/Dashboard.vue";

//faculty components
import FacultyExamOfficerDashboard from "../components/Views/Faculty/ExamOffice/Dashboard.vue";

//director's components
import DirectorDashboard from "../components/Views/Director/Dashboard.vue";

//department's components
import CordinatorDashboard from "../components/Views/Cordinator/Dashboard.vue";

//Super Admin components


import Enroll from '../components/Views/Enrollment/EnrollmentMultistepForm.vue';

// import Enroll from "../components/Views/Enrollment/EnrollmentMultistepForm.vue";

//-- email verification components
// import VerifyEmail from '../components/Views/EmailVerification/VerifyEmail.vue';

import Vue from 'vue';
import VueRouter from 'vue-router';
import AuthUser from '../apis/AuthUser';
import UserAPI from '../apis/User';

Vue.use(VueRouter);

const routes = [{
    path: '/',
    // redirect: '/login'
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../components/Home.vue'),
    meta: { guestUserOnly: true }
},

{
    path: '/change-password',
    name: 'Change Password',
    component: () => import(/* webpackChunkName: 'change-password' */ '../components/ChangePassword.vue'),
},

{
    path: "/reset-password",
    name: "Reset Password",
    component: () =>
        import(
            /* webpackChunkName: 'request-password-reset-link' */ "../components/Views/PasswordReset/RequestEmail"
        ),
    meta: { guestUserOnly: true },
},

{
    path: "/password-reset-link-sent",
    name: "Sent",
    component: () =>
        import(
            /* webpackChunkName: 'password-reset-lik-sent' */ "../components/Views/PasswordReset/PasswordResetLinkSent"
        ),
    meta: { guestUserOnly: true },
},
{
    path: "/reset-complete",
    name: "Reset Complete",
    component: () =>
        import(
            /* webpackChunkName: 'password-reset-complete' */ "../components/Views/PasswordReset/PasswordUpdated"
        ),
    meta: { guestUserOnly: true },
},
{
    path: "/reset-success",
    name: "Reset Success",
    component: () =>
        import(
            /* webpackChunkName: 'password-reset-success' */ "../components/Views/PasswordReset/ResetSuccess"
        ),
    meta: { guestUserOnly: true },
},
{
    path: "/reset-failure",
    name: "Reset Faiulure",
    component: () =>
        import(
            /* webpackChunkName: 'password-reset-failed' */ "../components/Views/PasswordReset/ResetFailure"
        ),
    meta: { guestUserOnly: true },
},

{
    path: "/login",
    redirect: '/',
    meta: { guestUserOnly: true },
},

{
    path: "/ssologin",
    name: "SSO Login",
    component: () =>
        import(/* webpackChunkName: "sso-login" */ "../components/SSOLogin"),
    meta: { guestUserOnly: true },
},

{
    path: "/candidate/login",
    name: "Candidate Login",
    redirect: "/pg/candidate/login",
},

{
    path: "/pg/candidate/login",
    name: "Postgraduate Candidate Login",
    component: () =>
        import(
                /* webpackChunkName: "local-login" */ "../components/LocalLogin"
        ),
    meta: { guestUserOnly: true },
},

{
    path: "/ug/candidate/:acronym?",
    name: "Undergraduate Candidate",
    component: () =>
        import(
                /* webpackChunkName: "ug-candidate-login" */ "../components/Views/Candidate/Undergraduate/UGPreadmissionLogin"
        ),
    meta: { guestUserOnly: true },
},

{
    path: '/ug/candidate/admission-processing-charges/confirm-payment-claim',
    name: 'Resolve Pre-Admission Fee Payment Claim',
    component: () => import(/* webpackChunkName: "ug-candidate-remita-confirm" */ '../components/Views/Candidate/Undergraduate/ConfirmRemitaPayment'),
    meta: { guestUserOnly: true }
},

{
    path: '/ug/applicant/admission-processing-charges/confirm-payment-claim',
    name: 'Resolve Applicant Pre-Admission Fee Payment Claim',
    component: () => import(/* webpackChunkName: "ug-applicant-remita-confirm" */ '../components/Views/Candidate/Undergraduate/PrivateInstitutions/ConfirmRemitaPayment'),
    meta: { guestUserOnly: true }
},

//post Graduate routes
{
    path: '/candidate/enroll',
    redirect: '/pg/candidate/enroll'
},

{
    path: '/pg/candidate/enroll',
    name: 'PG Enroll',
    component: () => import( /* webpackChunkName: "candidate-enrollment" */ '../components/Views/Pg/Enrollment/GetStarted'),
    meta: { guestUserOnly: true }
},
{
    path: '/application/referee/:candidateId/:identifier/:applicationId',
    name: 'Admission Referee',
    component: () => import( /* webpackChunkName: "admission-referee" */ '../components/Views/Pg/Enrollment/Referee'),
    meta: { guestUserOnly: true },
    props: true
},

{
    path: '/enroll',
    name: 'Enroll',
    component: Enroll,
    meta: { guestUserOnly: true }
},
{
    path: '/enrolled',
    name: 'Enrolled',
    component: () => import(/* webpackChunkName: "enroll-succuess" */ '../components/Views/Enrollment/EnrollmentAcknowledgement'),
    meta: { guestUserOnly: true }
},
{
    path: '/candidate/verify',
    name: 'Candidate Verification',
    component: () => import( /* webpackChunkName: "candidate-verification" */ '../components/Views/Admissions/CandidateVerification'),
    meta: { guestUserOnly: true }
},
{
    path: '/candidate/verification/complete',
    name: 'Candidate Verification Complete',
    component: () => import( /* webpackChunkName: "candidate-verification-complete" */ '../components/Views/Candidate/Undergraduate/VerificationComplete'),
    meta: { guestUserOnly: true }
},
{
    path: '/candidate/login/resent',
    name: 'Candidate Login Resent',
    component: () => import( /* webpackChunkName: "candidate-verification-complete" */ '../components/Views/Candidate/Undergraduate/PrivateInstitutions/LoginResent'),
    meta: { guestUserOnly: true }
},
{
    path: '/candidate/activate',
    name: 'Candidate Activation',
    component: () => import( /* webpackChunkName: "candidate-activation" */ '../components/Views/Admissions/CandidateActivation.vue'),
    meta: { guestUserOnly: true }
},

{
    path: '/candidate/below-general-cut-off',
    name: 'Below Minimum Cut-Off',
    component: () => import( /* webpackChunkName: "below-cutoff-mark" */ '../components/Views/Admissions/CandidateBelowInstitutionalCutOffMark.vue'),
    props: true,
    meta: { guestUserOnly: true }
},

{
    path: '/candidate/no-suggested-programme',
    name: 'No Suggested Programme',
    component: () => import( /* webpackChunkName: "no-suggested-programme" */ '../components/Views/Admissions/CandidateNoSuggestedProgramme.vue'),
    props: true,
    meta: { guestUserOnly: true }
},

{
    path: '/candidate/change-of-course',
    name: 'Change of Course',
    component: () => import( /* webpackChunkName: "change-course" */ '../components/Views/Admissions/ChangeOfCourse'),
    props: true,
    meta: { guestUserOnly: true }
},

{
    path: '/candidate/activated',
    name: 'Candidate Activated',
    component: () => import( /* webpackChunkName: "candidate-activated" */ '../components/Views/Admissions/CandidateAccountActivated'),
    props: true,
    meta: { guestUserOnly: true }
},
{
    path: '/no-access',
    name: 'AccessDenied',
    component: () => import(/* webpackChunkName: "unauthorized" */ '../components/UnAuthorized'),
    meta: { authenticatedUserOnly: true }
},
{
    path: "/make-payment",
    name: "Payment Template",
    component: () =>
        import(
            /* webpackChunkName: 'make-payment' */ "../components/Payment/PaymentComponents.vue"
        ),
    props: true,
    mode: "params",
},
{
    path: '/student',
    name: 'Student', //route name modified Home -> Student
    component: StudentDashboard,
    meta: { authenticatedUserOnly: true },
    beforeEnter: checkStudentRole,
    children: [{
        path: 'lectures',
        name: 'Lectures',
        component: () => import(/* webpackChunkName: 'student-lectures' */ '../components/Views/Student/Lectures'),
    },
    {
        path: 'profile',
        component: () => import(/* webpackChunkName: 'student-profile' */ '../components/Views/Student/StudentProfile'),
        name: "Student Profile",
        props: true
    },
    {
        path: 'courses',
        name: 'Courses',
        component: () => import(/* webpackChunkName: 'student-courses' */ '../components/Views/Student/Courses'),
    },
    {
        path: 'course-materials/:course_id',
        name: 'Course Naterials',
        component: () => import(/* webpackChunkName: 'course-materials' */ '../components/Views/Student/CourseMaterials'),
    },

    {
        path: 'inspired',
        name: 'My Inspired',
        component: () => import(/* webpackChunkName: 'student-inspired' */ '../components/Views/MyInspired/index'),
    },

    {
        path: 'course-registrations',
        name: 'My Course Registrations',
        component: () => import(/* webpackChunkName: 'student-course-reg' */ '../components/Views/Student/CourseRegistration'),
    },
    {
        path: 'announcements',
        name: 'Student Announcements',
        component: () => import(/* webpackChunkName: 'student-announcement' */ '../components/Views/Student/Announcements'),
    },

    {
        path: 'transfer-requests',
        name: 'Student Transfer Requsts',
        component: () => import(/* webpackChunkName: 'student-announcement' */ '../components/Views/Student/Transfers'),
    },

    {
        path: 'my-results',
        name: 'My Results',
        component: () => import(/* webpackChunkName: 'student-results' */ '../components/Views/Student/Results'),
    },

    {
        path: 'my-assignments',
        name: 'My Assignments',
        component: () => import(/* webpackChunkName: 'student-assignment' */ '../components/Views/Student/Assignments'),
    },
    {
        path: 'announcements',
        name: 'Announcements',
        component: () => import(/* webpackChunkName: 'student-announcement' */ '../components/Views/Student/Announcements'),
    },

    {
        path: "fees",
        component: () => import(/* webpackChunkName: 'student-fees-and-receipts' */ '../components/Views/Student/FeesAndReceiptsPrintOuts/FeesAndreceiptsHome'),
        children: [{
            path: "",
            name: 'Student Fees',
            component: () => import(/* webpackChunkName: 'student-fees' */ '../components/Views/Student/FeesAndReceiptsPrintOuts/SessionalRegistrationFees'),
        },
        {
            path: 'receipts-and-printouts',
            name: 'Student PrintOut',
            component: () => import(/* webpackChunkName: 'student-receipts' */ '../components/Views/Student/FeesAndReceiptsPrintOuts/PrintOut'),
        },
        ]
    },

    {
        path: "hostel-booking",
        component: () => import(/* webpackChunkName: 'student-fees-and-receipts' */ '../components/Views/Student/HostelBooking/BedspacesAndReservations'),
        children: [{
            path: "",
            name: 'Available Hostels',
            component: () => import(/* webpackChunkName: 'available-hostels' */ '../components/Views/Student/HostelBooking/AvailableHostels'),
        },
        {
            path: 'my-reservation',
            name: 'MyReservation',
            component: () => import(/* webpackChunkName: 'my-reservations' */ '../components/Views/Student/HostelBooking/MyReservations'),
        },
        ]
    },
    {
        path: 'assignment-info',
        name: 'Assignment-Info',
        component: () => import(/* webpackChunkName: 'student-assigment-info' */ '../components/AssignmentInfo'),
    },
    {
        path: 'cbt',
        component: () => import(/* webpackChunkName: 'student-cbt' */ '../components/Views/Student/CBT/Home'),
        children: [{
            path: '',
            component: () => import(/* webpackChunkName: 'student-cbt-overview' */ '../components/Views/Student/CBT/Overview'),
            name: "Student CBT"
        },
        {
            path: 'test/:name',
            component: () => import(/* webpackChunkName: 'student-test-attempt' */ '../components/Views/Student/CBT/TestAttempt'),
            props: true
        }
        ]
    },
    {
        path: 'student-time-table',
        component: () => import(/* webpackChunkName: 'student-timetable' */ '../components/Views/Student/Schedules/MyTimeTable.vue'),
        name: 'Student Time Table'
    },
    {
        path: 'settings',
        component: () => import(/* webpackChunkName: 'student-settings' */ '../components/Settings'),
        name: "Settings"
    },
    {
        path: 'course',
        component: () => import( /* webpackChunkName: "course-home" */ '../components/Views/Student/Course/CourseManagement'),
        children: [

            {
                path: "",
                name: 'My Course Registration',
                component: () => import(/* webpackChunkName: 'student-course-reg' */ '../components/Views/Student/Course/CourseRegistration'),
            },
            {
                path: "course-form",
                name: 'My Course Form',
                component: () => import(/* webpackChunkName: 'student-course-form' */ '../components/Views/Student/Course/CourseForm'),
            },
            {
                path: "extra-credit-applications",
                name: 'Extra Credit Applications',
                component: () => import(/* webpackChunkName: 'student-extra-credit-applications' */ '../components/Views/Student/Course/ExtraCreditApplications'),
            },
            {
                path: "add-and-drop",
                name: 'My Add and Drop',
                component: () => import(/* webpackChunkName: 'add-and-drop' */ '../components/Views/Student/Course/AddAndDrop'),
            },
        ]
    },

    {
        path: 'transaction-validation',
        name: 'Transactions Validation',
        component: () => import(/* webpackChunkName: 'transaction-validation' */ '../components/Views/Student/ValidateTransactions'),
    },

    {
        path: "convocation-fees",
        name: 'Convocation Fees',
        component: () => import(/* webpackChunkName: 'convocation-fees' */ '../components/Views/Student/ConvocationFees'),
    },
    {
        path: 'course-materials/:course_id',
        name: 'Student Course Naterials',
        component: () => import(/* webpackChunkName: 'course-materials' */ '../components/Views/Student/CourseMaterials'),
    },
    ],
},
// {
//     path: 'student/course-materials/:course_id',
//     name: 'Student Course Naterials',
//     component: () => import(/* webpackChunkName: 'course-materials' */ '../components/Views/Student/CourseMaterials'),
// },
{
    path: '/lecturer',
    name: 'Lecturer', //route name modified Home -> Lecturer
    component: LecturerDashboard,
    meta: { authenticatedUserOnly: true },
    beforeEnter: checkLecturerRole,
    children: [{
        path: 'lecturer-assignments/:course_id',
        name: 'Assignments',
        component: () => import(/* webpackChunkName: 'lecturer-assignement' */ '../components/Views/Lecturer/Assignments')
    },
    {
        path: 'course-info/:courseAllocationID',
        name: 'Course Info',
        component: () => import(/* webpackChunkName: 'lecturer-course-info' */ '../components/Views/Lecturer/CourseInfo'),
        props: true
    },

    {
        path: 'course-info/:courseId/synopsis/:courseAllocationID/:sessionId',
        name: 'Course Synopsis',
        component: () => import(/* webpackChunkName: 'lecturer-course-synopsis' */ '../components/Views/Lecturer/CourseSynopsis'),
        props: true
    },
    {
        path: 'assignment-info/:assignment_id',
        name: 'Assignment Info',
        component: () => import(/* webpackChunkName: 'lecturer-assigment-info' */ '../components/AssignmentInfo'),
    },
    {
        path: 'submitted-assignment',
        name: 'Submitted Assignments',
        component: () => import(/* webpackChunkName: 'lecturer-submitted-assignment' */ '../components/Views/Lecturer/SubmittedAssignment'),
    },
    {
        path: 'my-courses',
        name: 'My Courses',
        component: () => import(/* webpackChunkName: 'lecturer-courses' */ '../components/Views/Lecturer/MyCourses'),
    },

    {
        path: 'announcements',
        name: 'Lecturer Announcements',
        component: () => import(/* webpackChunkName: 'student-announcement' */ '../components/Views/Lecturer/Announcements'),
    },

    {
        path: 'inspired',
        name: 'Access My Inspired',
        component: () => import(/* webpackChunkName: 'lecturer-inspired' */ '../components/Views/MyInspired/index'),
    },
    {
        path: 'support',
        component: () => import(/* webpackChunkName: "lecturer-support" */ '../components/Views/Lecturer/Support.vue'),
        name: "Lecturer Support",
        props: true
    },

    {
        path: 'profile/:user',
        component: () => import(/* webpackChunkName: 'lecturer-profile' */ '../components/Views/Lecturer/LecturerProfile'),
        name: "Lecturer Profile",
        props: true
    },
    {
        path: 'add-assignment/:course_id',
        component: () => import(/* webpackChunkName: 'lecturer-add-assignment' */ '../components/Views/Lecturer/AddAssignment'),
        name: "Add Assignment"
    },
    {
        path: 'add-assessment/:course_id/:session_id/:semester_id',
        component: () => import(/* webpackChunkName: 'lecturer-add-assessment' */ '../components/Views/Lecturer/AddAssessment'),
        name: "Add Assessment"
    },

    {
        path: 'lectures-list',
        component: () => import(/* webpackChunkName: 'lectures-list' */ '../components/Views/Lecturer/LecturesList'),
        name: 'Lecturer Lectures'
    },
    {
        path: 'lecture-meet/:mid',
        props: true,
        component: () => import(/* webpackChunkName: 'lectures-meet' */ '../components/Views/VirtualClass/LectureMeet'),
        name: 'LectureMeet'
    },
    {
        path: 'cbt',
        component: () => import(/* webpackChunkName: 'lecturer-cbt-home' */ '../components/Views/Lecturer/CBT/Home'),
        children: [{
            path: '',
            component: () => import(/* webpackChunkName: 'lecturer-cbt-list' */ '../components/Views/Lecturer/CBT/List'),
            name: "CBT",
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: 'lecturer-cbt-add' */ '../components/Views/Lecturer/CBT/Add'),
            name: "Create CBT",
        },
        {
            path: 'questions',
            component: () => import(/* webpackChunkName: 'lecturer-cbt-questions' */ '../components/Views/Lecturer/CBT/Questions'),
            name: "CBT Questions",
        }
        ]
    },
    {
        path: 'time-table',
        component: () => import(/* webpackChunkName: 'lecturer-timetable' */ '../components/Views/Lecturer/Schedules/MyTimeTable'),
        name: 'Lecturer Time Table'
    },

    ]
},

//routings to the Institution Super Admin sections
{
    path: '/admin',
    component: () => import(/* webpackChunkName: "admin-dashboard" */ '../components/Views/Admin/AdminDashboard.vue'),
    name: 'Admin',
    meta: { authenticatedUserOnly: true },
    beforeEnter: checkSuperAdminRole,
    children: [{
        path: 'overview',
        component: () => import(/* webpackChunkName: "admin-overview" */ '../components/Views/Admin/AdminOverview.vue'),
        name: 'Administrator Home',
    },
    {
        path: "institutes",
        name: "Institutes",
        component: () =>
            import(
                /* webpackChunkName: 'institutes-home' */ "../components/Views/Institutes/Overview"
            ),
    },
    {
        path: 'user-profile/:user',
        component: () => import(/* webpackChunkName: "super-admin-profile" */ '../components/Views/Admin/Profiles/SuperAdminProfile.vue'),
        name: "Super Admin Profile",
        props: true
    },
    {
        path: 'institution/profile',
        component: () => import(/* webpackChunkName: "institution-profile" */ '../components/Views/Admin/Profiles/InstitutionProfile.vue'),
        name: "Institution Profile",
        props: true
    },

    {
        path: 'institution/support',
        component: () => import(/* webpackChunkName: "institution-support" */ '../components/Views/Admin/Support/Support.vue'),
        name: "Admin Support",
        props: true
    },

    {
        path: 'institution/themes',
        component: () => import(/* webpackChunkName: "institution-themes" */ '../components/Views/Admin/Themes/Themes.vue'),
        name: "Institution Theme",
        props: true
    },

    {
        path: 'users',
        component: () => import( /* webpackChunkName: "users-home" */ '../components/Views/Admin/Users/UsersManagement'),
        children: [{
            path: "",
            name: 'Manage Students',
            component: () => import( /* webpackChunkName: "users-students" */ '../components/Views/Admin/Users/StudentManagement')
        },
        {
            path: "lecturers",
            name: 'Manage Lecturers',
            component: () => import( /* webpackChunkName: "users-lecturers" */ '../components/Views/Admin/Users/LecturerManagement')
        },
        {
            path: "supporting-staff",
            name: 'Manage Supporting Staff',
            component: () => import( /* webpackChunkName: "users-supporting-staff" */ '../components/Views/Admin/Users/SupportStaffManagement'),
        },
    ]
    },
    {
        path: "users/students/:id",
        name: 'Student Profile',
        component: () => import( /* webpackChunkName: "users-student-profile" */ '../components/Views/Admin/Users/StudentProfile.vue'),
    },
    
    {
        path: "users/lecturers/:id",
        name: 'Lecturer Profile',
        component: () => import( /* webpackChunkName: "users-lecturer-profile" */ '../components/Views/Admin/Users/LecturerProfile.vue'),
    },
    {
        path: "users/supporting-staff/:id",
        name: 'Support Staff Profile',
        component: () => import( /* webpackChunkName: "users-supporting-profile" */ '../components/Views/Admin/Users/LecturerProfile.vue'),
    },
    {
        path: 'sub-admins',
        component: () => import( /* webpackChunkName: "sub-admins" */ '../components/Views/Admin/RolesAndPermissions/SubAdmins'),
        name: 'Manage Sub Admins',
    },
    {
        path: 'campuses',
        component: () => import( /* webpackChunkName: "manage-campuses" */ '../components/Views/Admin/Campuses/CampusAndStudyCenter'),
        children: [{
            path: "",
            name: 'Manage Campuses',
            component: () => import( /* webpackChunkName: "campus-lists" */ '../components/Views/Admin/Campuses/CampusList')
        },
        {
            path: "study-centres",
            name: 'Study Centres',
            component: () => import( /* webpackChunkName: "study-centre-list" */ '../components/Views/Admin/Campuses/StudyCenterList')
        },
        ]
    },
    {
        path: 'affiliations',
        component: () => import( /* webpackChunkName: "manage-affiliates" */ '../components/Views/Admin/Affiliations/Affiliation'),
        name: 'Manage Affiliations',
    },
    {
        path: "faculties",
        //name: 'Manage Faculties & Departments',
        component: () => import( /* webpackChunkName: "faculty-departments" */ '../components/Views/Admin/FacultiesAndDepartments/FacultiesAndDepartements'),
        //props: true,
        children: [{
            path: "",
            name: 'Manage Faculties',
            component: () => import( /* webpackChunkName: "faculty-lists" */ '../components/Views/Admin/FacultiesAndDepartments/FacultyList')
        },
        {
            path: "departments",
            name: 'Manage Departments',
            component: () => import( /* webpackChunkName: "department-list" */ '../components/Views/Admin/FacultiesAndDepartments/DepartmentList')
        },
        {
            path: "academic-units",
            name: 'Manage Academic Units',
            component: () => import( /* webpackChunkName: "academic-unit-list" */ '../components/Views/Admin/FacultiesAndDepartments/AcademicUnitList')
        },
        ]
    },

    {
        path: "directorates",
        component: () => import( /* webpackChunkName: "directorates-and-units" */ '../components/Views/Admin/Directorates/DirectoratesAndUnits'),
        children: [{
            path: "",
            name: 'Manage Centres and Directorates',
            component: () => import( /* webpackChunkName: "directorates-list" */ '../components/Views/Admin/Directorates/Directorates'),
        },
        {
            path: "service-units",
            name: 'Manage Service Units',
            component: () => import( /* webpackChunkName: "service-unit-list" */ '../components/Views/Admin/Directorates/ServiceUnits'),
        },
        ]
    },

    {
        path: "faculty/profile",
        name: "Faculty Profile",
        component: () =>
            import(
                /* webpackChunkName: "faculty-profile" */
                "../components/Views/Admin/FacultiesAndDepartments/FacultyProfile"
            ),
        props: true,
    },

    {
        path: 'department/profile',
        name: 'Department Profile',
        component: () => import( /* webpackChunkName: "department-profile" */ '../components/Views/Admin/FacultiesAndDepartments/DepartmentProfile'),
        props: true,
    },

    {
        path: 'academic-unit/profile',
        name: 'Academic Unit Profile',
        component: () => import( /* webpackChunkName: "academic-unit-profile" */ '../components/Views/Admin/FacultiesAndDepartments/AcademicUnitProfile'),
        props: true,
    },

    {
        path: 'institute/profile',
        name: 'Institute Profile',
        component: () => import( /* webpackChunkName: "institute-profile" */ '../components/Views/Institutes/InstituteProfile'),
        props: true,
    },

    {
        path: 'programmes',
        // name: 'Manage Academic Programmes & Courses',
        component: () => import( /* webpackChunkName: "programmes-and-course-home" */ '../components/Views/Admin/ProgrammesAndCourses/ProgrammesAndCourses'),
        children: [{
            path: '',
            name: 'Manage Academic Programmes',
            component: () => import( /* webpackChunkName: "mainstream-academic-programmes" */ '../components/Views/Admin/ProgrammesAndCourses/ProgrammeList')
        },
        {
            path: "affiliate-programmes",
            name: 'Manage Affiliate Academic Programmes',
            component: () => import( /* webpackChunkName: "affiliate-academic-programmes" */ '../components/Views/Admin/ProgrammesAndCourses/AffiliateProgrammeList')
        },

        {
            path: "courses",
            name: 'Manage Courses',
            component: () => import( /* webpackChunkName: "managecourse-list" */ '../components/Views/Admin/ProgrammesAndCourses/CourseList')
        },

        {
            path: "credit-load-limit",
            name: 'Manage Credit Load Limit',
            component: () => import( /* webpackChunkName: "manage-credit-load-limit-list" */ '../components/Views/Admin/ProgrammesAndCourses/CreditLoadLimitList')
        },
        ]
    },
    {
        path: 'matric-no-template',
        component: () => import( /* webpackChunkName: "matric-no-template-home" */ '../components/Views/Admin/MatricNumber/MatricNumberTemplate'),
        children: [{
            path: '',
            name: 'Manage Matric Template',
            component: () => import( /* webpackChunkName: "matric-template-list" */ '../components/Views/Admin/MatricNumber/MatricNumberTemplateList')
        },
        {
            path: 'generate-matric-no',
            name: 'Generate Matric Number',
            component: () => import( /* webpackChunkName: "generate-matric-no" */ '../components/Views/Admin/MatricNumber/GenerateMatricNumber')
        },
        {
            path: 'matric-number-buffer',
            name: 'Matric Number Buffer',
            component: () => import( /* webpackChunkName: "matric-number-buffer" */ '../components/Views/Admin/MatricNumber/MatricNumberExemptionList')
        },
        {
            path: 'matric-number-list',
            name: 'Matric Number List',
            component: () => import( /* webpackChunkName: "matric-number-list" */ '../components/Views/Admin/MatricNumber/MatriculatedList')
        },
        ]
    },
    {
        path: 'students-registration-list',
        component: () => import( /* webpackChunkName: "students-registration-list-home" */ '../components/Views/Admin/StudentsRegistrationList/StudentsRegistration'),
        children: [
            {
                path: '',
                name: 'Manage Students Registration',
                component: () => import( /* webpackChunkName: "students-registration-list" */ '../components/Views/Admin/StudentsRegistrationList/StudentsRegistrationList')
            },
            {
                path: 'submission-list',
                name: 'View Registration Submission List',
                component: () => import( /* webpackChunkName: "students-registration-submission-list" */ '../components/Views/Admin/StudentsRegistrationList/StudentsRegistrationSubmissionList')
            },
            {
                path: 'enrollment-list',
                name: 'View Course Enrollment List',
                component: () => import( /* webpackChunkName: "students-courses-enrollment-list" */ '../components/Views/Admin/StudentsRegistrationList/StudentsCoursesEnrollmentList')
            },
        ]
    },
    {
        path: 'submission-list/information',
        name: "Student's Course Registration Information",
        component: () => import( /* webpackChunkName: 'admin-students-course-registration-information' */ '../components/Views/Admin/StudentsRegistrationList/StudentCourseRegistrationInformation'),
        props: true
    },
    {
        path: 'students-results-list',
        component: () => import( /* webpackChunkName: "manage-students-results-list" */ '../components/Views/Admin/StudentsResults/StudentsResultList'),
        name: 'Manage Student Results',
    },
    {
        path: 'admin-student-results',
        component: () => import( /* webpackChunkName: "admin-student-results" */ '../components/Views/Admin/StudentsResults/StudentResults'),
        name: 'Admin Student Results',
    },
    {
        path: 'academic_sessions',
        // name: 'Manage Sessions & Semseters',
        component: () => import( /* webpackChunkName: "semester-and-session-home" */ '../components/Views/Admin/SessionsAndSemesters/SessionsAndSemesters'),
        children: [{
            path: '',
            name: 'Manage Academic Sessions',
            component: () => import( /* webpackChunkName: "academic-sessions-list" */ '../components/Views/Admin/SessionsAndSemesters/AcademicSessions'),
        },
        {
            path: "semesters",
            name: 'Manage Semesters',
            component: () => import( /* webpackChunkName: "semesters-list" */ '../components/Views/Admin/SessionsAndSemesters/Semesters'),
        },
        {
            path: 'session_semester_settings',
            name: 'Session & Semseter Configurations',
            component: () => import( /* webpackChunkName: "session-semester-setting" */ '../components/Views/Admin/SessionsAndSemesters/SessionAndSemesterSettings')
        },
        {
            path: "late-registrations",
            name: 'Manage Late Registrations',
            component: () => import( /* webpackChunkName: "late-registrations-list" */ '../components/Views/Admin/SessionsAndSemesters/LateRegistrations'),
        },
        {
            path: "special-sessions",
            name: 'Manage Special Sessions',
            component: () => import( /* webpackChunkName: "late-registrations-list" */ '../components/Views/Admin/SessionsAndSemesters/SpecialSessions'),
        },
        ]
    },

    {
        path: 'admin-announcement',
        component: () => import( /* webpackChunkName: "manage-announcement-list" */ '../components/Views/Admin/Announcements/AnnouncementsList'),
        name: 'Manage Announcement',
    },


    {
        path: 'ranks',
        // name: "Manage Ranks",
        component: () => import( /* webpackChunkName: "ranks-home" */ '../components/Views/Admin/Ranks/Ranks'),
        children: [{
            path: '',
            name: 'Manage Academic Ranks',
            component: () => import( /* webpackChunkName: "academic-ranks-list" */ '../components/Views/Admin/Ranks/AcademicRank')
        },
        {
            path: "non-academic-ranks",
            name: 'Manage Non-Academic Ranks',
            component: () => import( /* webpackChunkName: "non-academic-ranks-list" */ '../components/Views/Admin/Ranks/NonAcademicRank')
        },
        {
            path: "rank-cadres",
            name: 'Manage Rank Cadres',
            component: () => import( /* webpackChunkName: "rank-cadres-list" */ '../components/Views/Admin/Ranks/RankCadres')
        },
        ]
    },
    {
        path: 'degree-categories',
        name: "Manage Academic Degrees",
        component: () => import( /* webpackChunkName: "academic-degree-categories" */  '../components/Views/Admin/AcademicDegrees/AcademicDegreeCategory')
    },

    {
        path: 'academic-levels',
        // name: 'Students & Academic Programmes Levels',
        component: () => import( /* webpackChunkName: "academic-levels-home" */  '../components/Views/Admin/AcademicLevels/Levels'),
        children: [
            {
                path: "",
                name: 'Manage Academic Programme Levels',
                component: () => import( /* webpackChunkName: "program-academic-levels" */  '../components/Views/Admin/AcademicLevels/AcademicProgrammeLevels'),
            },
            {
                path: 'student-levels',
                name: 'Manage Students Academic Levels',
                component: () => import( /* webpackChunkName: "student-academic-levels" */  '../components/Views/Admin/AcademicLevels/StudentAcademicLevels')
            },
            {
                path: 'students-level-progression',
                name: 'Students Academic Level Progression',
                component: () => import( /* webpackChunkName: "student-level-progression" */  '../components/Views/Admin/AcademicLevels/StudentAcademicLevelProgression')
            },
        ]
    },

    {
        path: 'time-tables',
        // name: "Manage Time Tables",
        component: () => import( /* webpackChunkName: "time-tables-home" */  '../components/Views/Admin/TimeTables/TimeTablingHome'),
        children: [{
            path: '',
            name: 'Manage Time Tables',
            component: () => import( /* webpackChunkName: "time-table-list" */  '../components/Views/Admin/TimeTables/TimeTables'),
        },
        {
            path: "profiles",
            name: 'Manage TimeTable Profiles',
            component: () => import( /* webpackChunkName: "time-table-profiles-list" */  '../components/Views/Admin/TimeTables/TimeTableProfiles'),
        },
        {
            path: "lecture-venues",
            name: 'Manage Lecture Venues',
            component: () => import( /* webpackChunkName: "lecture-venues-list" */  '../components/Views/Admin/TimeTables/LectureVenues'),
        },
        ]
    },
    {
        path: "time-tables/editor",
        name: 'Edit Time Table',
        component: () => import( /* webpackChunkName: "time-table-editor" */  '../components/Views/Admin/TimeTables/TimeTableEditor'),
        props: true
    },
    {
        path: "roles",
        name: 'Manage Roles & Permissions',
        component: () => import( /* webpackChunkName: 'roles-permissions-home' */ '../components/Views/Admin/RolesAndPermissions/Roles'),
    },
    {
        path: 'transfer-requests',
        name: 'Transfer Requests',
        component: () => import(/* webpackChunkName: 'admin-transfer-requests' */ '../components/Views/Admin/Transfers/Home'),
        children: [{
            path: "all",
            name: 'All Transfer Requests',
            component: () => import( /* webpackChunkName: "admin-transfer-request-all" */ '../components/Views/Admin/Transfers/All.vue'),
        },
        {
            path: "pending-release",
            name: 'Pending Release Transfer Requests',
            component: () => import( /* webpackChunkName: "admin-transfer-request-release" */ '../components/Views/Admin/Transfers/PendingRelease'),
        },
        {
            path: "pending-acceptance",
            name: 'Pending Acceptance Transfer Requests',
            component: () => import( /* webpackChunkName: "admin-transfer-request-acceptance" */ '../components/Views/Admin/Transfers/PendingAcceptance'),
        },
        {
            path: "pending-approval",
            name: 'Pending Approval Transfer Requests',
            component: () => import( /* webpackChunkName: "admin-transfer-request-approval" */ '../components/Views/Admin/Transfers/PendingApproval'),
        },

        ]
    },

    {
        path: "extra-credit-applications",
        name: 'Extra Credit Applications',
        component: () => import(/* webpackChunkName: 'extra-credit-applications' */ '../components/Views/Admin/ExtraCreditApplications/ExtraCreditApplications'),
    },

    {
        path: "role/grant-revoke-permissions",
        name: 'Grant & Revoke Permissions',
        component: () => import( /* webpackChunkName: 'grant-revoke permissions' */ '../components/Views/Admin/RolesAndPermissions/GrantAndRevokeRolePermissions'),
        props: true,
    },
    {
        path: "grade-profile/editor",
        name: 'Edit Grade Profile',
        component: () => import( /* webpackChunkName: "time-table-editor" */  '../components/Views/Admin/Grading/GradeProfileEditor'),
        props: true
    },

    {
        path: "student-status",
        name: 'Manage Students Status',
        beforeEnter: enforceRequestParameter,
        component: () => import( /* webpackChunkName: "time-table-editor" */  '../components/Views/Admin/StudentStatus/StudentStatus'),
        props: (route) => ({ id: route.query.id }),
    },

    {
        path: "change-of-programme",
        name: 'Change of Programm',
        component: () => import( /* webpackChunkName: "change-of-programme" */ '../components/Views/Admin/ProgrammesAndCourses/ChangeOfProgramme')
    },
    ]
},

{ //-------------------routings to the departmental offices------//beforeEnter: checkHODRole,-------------------//
    path: '/department',
    name: 'Department Dashboard',
    component: DepartmentDashboard,
    beforeEnter: checkHODRole,
    meta: { authenticatedUserOnly: true },
    children: [{
        path: 'overview',
        name: 'Department Home',
        component: () => import( /* webpackChunkName: 'department-home' */ '../components/Views/Department/DepartmentOverview')
    },
    {
        path: 'staff',
        name: 'Departmental Staff Members',
        component: () => import( /* webpackChunkName: 'department-staff-list' */ '../components/Views/Department/Staff')
    },
    {
        path: 'programmes',
        name: 'Departmental Programmes',
        component: () => import( /* webpackChunkName: 'departmental-programmes-list' */ "../components/Views/Department/DepartmentalProgrammes")
    },
    {
        path: 'transfer-requests',
        name: 'Department Transfer Requests',
        component: () => import(/* webpackChunkName: 'transfer-requests' */ '../components/Views/Department/Transfers/TransferHome'),
        children: [{
            path: "inbound",
            name: 'Inbound Transfer Requests',
            component: () => import( /* webpackChunkName: "rebates" */ '../components/Views/Department/Transfers/InboundTransfer'),
        },
        {
            path: "outbound",
            name: 'Outbound Transfer Requests',
            component: () => import( /* webpackChunkName: "rebates-grant" */ '../components/Views/Department/Transfers/OutboundTransfer'),
        },

        ]
    },
    {
        path: 'courses',
        name: 'Departmental Courses',
        component: () => import( /* webpackChunkName: 'departmental-courses' */ "../components/Views/Department/DepartmentalCourses")
    },
    {
        path: 'course-allocations',
        name: 'Departmental Course Allocations',
        component: () => import( /* webpackChunkName: 'departmental-course-allocation' */ '../components/Views/Department/CourseAllocations')
    },
    {
        path: 'academic-advisers',
        name: 'Academic Level Advisers',
        component: () => import( /* webpackChunkName: 'departmental-academic-advisers' */ '../components/Views/Department/LevelAdvisers')
    },
    {
        path: 'appoint-exam-officer',
        name: 'Appoint Exam Officer',
        component: () => import( /* webpackChunkName: 'departmental-appoint-exam-officer' */ '../components/Views/Department/AppointExamOfficer')
    },
    {
        path: 'students',
        name: 'Departmental Students',
        component: () => import( /* webpackChunkName: 'departmental-students-list' */ '../components/Views/Department/Students')
    },
    {
        path: 'students-results',
        name: 'Departmental Students Results',
        component: () => import( /* webpackChunkName: 'departmental-students-results-list' */ '../components/Views/Department/MyStudentResults')
    },
    {
        path: 'student-results/result',
        name: "Departmental Student's Result",
        component: () => import( /* webpackChunkName: 'departmental-students-results' */ '../components/Views/Department/StudentResults'),
        props: true
    },
    {
        path: "assessment-score",
        name: 'Upload Assessment Score',
        component: () => import( /* webpackChunkName: "departmental-assessment-score" */ '../components/Views/Department/AssessmentScore'),
    },
    {
        path: 'students-registration-list',
        component: () => import( /* webpackChunkName: "departmental-students-registration-list-home" */ '../components/Views/Department/StudentsRegistrationList/StudentsRegistration'),
        children: [
            {
                path: '',
                name: 'Departmental View Registration Submission List',
                component: () => import( /* webpackChunkName: "departmental-students-registration-submission-list" */ '../components/Views/Department/StudentsRegistrationList/StudentsRegistrationSubmissionList')
            },
            {
                path: 'enrollment-list',
                name: 'Departmental View Course Enrollment List',
                component: () => import( /* webpackChunkName: "departmental-students-courses-enrollment-list" */ '../components/Views/Department/StudentsRegistrationList/StudentsCoursesEnrollmentList')
            },
        ]
    },
    {
        path: 'course-registrations',
        name: 'Departmental Course Registrations',
        component: () => import( /* webpackChunkName: 'departmental-course-registrations' */ '../components/Views/Department/DepartmentalCoursesRegistrations')
    },
    {
        path: 'extra-credit-applications',
        name: 'Extra Credit Applications',
        component: () => import( /* webpackChunkName: 'departmental-extra-credit-applications' */ '../components/Views/Department/ExtraCreditApplications')
    },
    {
        path: "/verify-email",
        name: "Verify Email",
        component: () =>
            import(
                /* webpackChunkName: 'request-email-verification-link' */ "../components/Views/EmailVerification/VerifyEmail"
            ),
        meta: { guestUserOnly: true },
    },
    {
        path: 'departmental-progression',
        name: 'Departmental Students Progresion',
        component: () => import( /* webpackChunkName: 'department-progression' */ '../components/Views/Department/StudentProgression')
    },
    ]
},

{ //-------------------routings to the cordinator offices------//beforeEnter: checkHODRole,-------------------//
    path: '/cordinator',
    name: 'Cordinator Dashboard',
    component: CordinatorDashboard,
    beforeEnter: checkCordinatorRole,
    meta: { authenticatedUserOnly: true },
    children: [{
        path: 'overview',
        name: 'Cordinator Home',
        component: () => import( /* webpackChunkName: 'cordinator-home' */ '../components/Views/Cordinator/Overview')
    },
    {
        path: 'staff',
        name: 'Cordinator Staff Members',
        component: () => import( /* webpackChunkName: 'cordinator-staff-list' */ '../components/Views/Cordinator/Staff')
    },
    {
        path: 'programmes',
        name: 'Cordinator Programmes',
        component: () => import( /* webpackChunkName: 'cordinator-programmes-list' */ "../components/Views/Cordinator/Programmes")
    },
    {
        path: 'transfer-requests',
        name: 'Cordinator Transfer Requests',
        component: () => import(/* webpackChunkName: 'cordinator-transfer-requests' */ '../components/Views/Cordinator/Transfers/TransferHome'),
        children: [{
            path: "inbound",
            name: 'Inbound Transfer Requests',
            component: () => import( /* webpackChunkName: "cordinator-rebates" */ '../components/Views/Cordinator/Transfers/InboundTransfer'),
        },
        {
            path: "outbound",
            name: 'Outbound Transfer Requests',
            component: () => import( /* webpackChunkName: "cordinator-rebates-grant" */ '../components/Views/Cordinator/Transfers/OutboundTransfer'),
        },

        ]
    },
    {
        path: 'courses',
        name: 'Cordinator Courses',
        component: () => import( /* webpackChunkName: 'cordinator-courses' */ "../components/Views/Cordinator/Courses")
    },
    {
        path: 'course-allocations',
        name: 'Cordinator Course Allocations',
        component: () => import( /* webpackChunkName: 'cordinator-course-allocation' */ '../components/Views/Cordinator/CourseAllocations')
    },
    {
        path: 'academic-advisers',
        name: 'Academic Level Advisers',
        component: () => import( /* webpackChunkName: 'cordinator-academic-advisers' */ '../components/Views/Cordinator/LevelAdvisers')
    },
    {
        path: 'appoint-exam-officer',
        name: 'Appoint Exam Officer',
        component: () => import( /* webpackChunkName: 'cordinator-appoint-exam-officer' */ '../components/Views/Cordinator/AppointExamOfficer')
    },
    {
        path: 'students',
        name: 'Cordinator Students',
        component: () => import( /* webpackChunkName: 'cordinator-students-list' */ '../components/Views/Cordinator/Students')
    },
    {
        path: 'students-results',
        name: 'Cordinator Students Results',
        component: () => import( /* webpackChunkName: 'cordinator-students-results-list' */ '../components/Views/Cordinator/MyStudentResults')
    },
    {
        path: 'student-results/result',
        name: "Cordinator Student's Result",
        component: () => import( /* webpackChunkName: 'cordinator-students-results' */ '../components/Views/Cordinator/StudentResults'),
        props: true
    },
    {
        path: "assessment-score",
        name: 'Upload Assessment Score',
        component: () => import( /* webpackChunkName: "cordinator-assessment-score" */ '../components/Views/Cordinator/AssessmentScore'),
    },
    {
        path: 'students-registration-list',
        component: () => import( /* webpackChunkName: "cordinator-students-registration-list-home" */ '../components/Views/Cordinator/StudentsRegistrationList/StudentsRegistration'),
        children: [
            {
                path: '',
                name: 'Cordinator View Registration Submission List',
                component: () => import( /* webpackChunkName: "cordinator-students-registration-submission-list" */ '../components/Views/Cordinator/StudentsRegistrationList/StudentsRegistrationSubmissionList')
            },
            {
                path: 'enrollment-list',
                name: 'Cordinator View Course Enrollment List',
                component: () => import( /* webpackChunkName: "cordinator-students-courses-enrollment-list" */ '../components/Views/Cordinator/StudentsRegistrationList/StudentsCoursesEnrollmentList')
            },
        ]
    },
    {
        path: 'course-registrations',
        name: 'Cordinator Course Registrations',
        component: () => import( /* webpackChunkName: 'cordinator-course-registrations' */ '../components/Views/Cordinator/CoursesRegistrations')
    },
    {
        path: 'extra-credit-applications',
        name: 'Extra Credit Applications',
        component: () => import( /* webpackChunkName: 'cordinator-extra-credit-applications' */ '../components/Views/Cordinator/ExtraCreditApplications')
    },
    {
        path: "/verify-email",
        name: "Verify Email",
        component: () =>
            import(
                /* webpackChunkName: 'request-email-verification-link' */ "../components/Views/EmailVerification/VerifyEmail"
            ),
        meta: { guestUserOnly: true },
    },
    {
        path: 'departmental-progression',
        name: 'Cordinator Students Progresion',
        component: () => import( /* webpackChunkName: 'department-progression' */ '../components/Views/Cordinator/StudentProgression')
    },
    ]
},

{ //-------------------routings to the departmental offices------//beforeEnter: checkHODRole,-------------------//
    path: '/director',
    name: 'Director Dashboard',
    component: DirectorDashboard,
    beforeEnter: checkDirectorRole,
    meta: { authenticatedUserOnly: true },
    children: [{
        path: 'overview',
        name: 'Director Home',
        component: () => import( /* webpackChunkName: 'director-home' */ '../components/Views/Director/Overview')
    },
    {
        path: 'courses',
        name: 'Director Courses',
        component: () => import( /* webpackChunkName: 'director-courses' */ "../components/Views/Director/Courses")
    },
    {
        path: 'course-allocations',
        name: 'Director Course Allocations',
        component: () => import( /* webpackChunkName: 'director-course-allocation' */ '../components/Views/Director/CourseAllocations')
    },
    {
        path: 'appoint-exam-officer',
        name: 'Director Appoint Exam Officer',
        component: () => import( /* webpackChunkName: 'director-appoint-exam-officer' */ '../components/Views/Director/AppointExamOfficer')
    },
    {
        path: "assessment-score",
        name: 'Director Upload Assessment Score',
        component: () => import( /* webpackChunkName: "director-assessment-score" */ '../components/Views/Director/AssessmentScore'),
    },
    {
        path: 'course-registrations',
        name: 'Director Course Registrations',
        component: () => import( /* webpackChunkName: 'director-course-registrations' */ '../components/Views/Director/CoursesRegistrations')
    },
    ]
},

{
    //-------------------routings to the level adviser offices------//beforeEnter: checkHODRole,-------------------//
    path: "/academic-adviser",
    name: "Academic Adviser Dashboard",
    component: AdviserDashboard,
    meta: { authenticatedUserOnly: true },
    children: [
        {
            path: "overview",
            name: "Academic Level Adviser Home",
            component: () =>
                import(
                    /* webpackChunkName: 'academic-adviser-home' */ "../components/Views/AcademicLevelAdviser/AdviserOverview"
                ),
        },
        {
            path: "course-registration-template",
            name: "Level Course Registration Template ",
            component: () =>
                import(
                    /* webpackChunkName: 'academic-adviser-course-specification' */ "../components/Views/AcademicLevelAdviser/CourseSpecification"
                ),
        },
        {
            path: "my-students",
            name: "My Students",
            component: () =>
                import(
                    /* webpackChunkName: 'academic-adviser-students-list' */ "../components/Views/AcademicLevelAdviser/MyStudents.vue"
                ),
        },
        {
            path: "students-course-reg",
            name: "My Students Course Registration",
            component: () =>
                import(
                    /* webpackChunkName: 'academic-adviser-students-course-registration' */ "../components/Views/AcademicLevelAdviser/MyStudentsCourseRegistration.vue"
                ),
        },
        {
            path: "students-course-reg/documents",
            name: "Student's Course Registration Documents",
            component: () =>
                import(
                    /* webpackChunkName: 'academic-adviser-students-course-registration-documents' */ "../components/Views/AcademicLevelAdviser/StudentCourseRegistrationDocuments"
                ),
            props: true,
        },
        {
            path: 'my-student-results',
            name: 'My Student Results',
            component: () => import( /* webpackChunkName: 'academic-adviser-students-result-list' */ '../components/Views/AcademicLevelAdviser/MyStudentResults')
        },
        {
            path: 'my-student-results/result',
            name: "Student's Result",
            component: () => import( /* webpackChunkName: 'academic-adviser-students-result' */ '../components/Views/AcademicLevelAdviser/StudentResults'),
            props: true
        },
        {
            path: "students-extra-credit-applications",
            name: 'Extra Credit Applications',
            component: () => import(/* webpackChunkName: 'academic-adviser-student-extra-credit-applications' */ '../components/Views/AcademicLevelAdviser/ExtraCreditApplications'),
        },
    ]
},

{
    //-------------------routings to the level adviser offices------//beforeEnter: checkHODRole,-------------------//
    path: "/department-exam-officer",
    name: "Department Exam Officer Dashboard",
    component: ExamOfficerDashboard,
    meta: { authenticatedUserOnly: true },
    children: [
        {
            path: "overview",
            name: "Department Exam Officer Home",
            component: () =>
                import(
                    /* webpackChunkName: 'department-exam-officer-home' */ "../components/Views/Department/ExamOffice/ExamOfficerOverview"
                ),
        },
        {
            path: "students",
            name: "Department Exam Officer Students",
            component: () =>
                import(
                    /* webpackChunkName: 'department-exam-officer-students-list' */ "../components/Views/Department/ExamOffice/Students.vue"
                ),
        },
        {
            path: 'students-results',
            name: 'Department Exam Officer Students Results',
            component: () => import( /* webpackChunkName: 'department-exam-officer-students-results-list' */ '../components/Views/Department/ExamOffice/MyStudentResults')
        },
        {
            path: 'student-results/result',
            name: "Department Exam Officer Student's Result",
            component: () => import( /* webpackChunkName: 'department-exam-officer-students-results' */ '../components/Views/Department/ExamOffice/StudentResults'),
            props: true
        },
        // {
        //     path: "assessment-score",
        //     name: 'Upload Assessment Score',
        //     component: () => import( /* webpackChunkName: "department-exam-officer-assessment-score" */ '../components/Views/Department/ExamOffice/AssessmentScore'),
        // },
        {
            path: 'courses',
            name: 'Department Exam Officer Courses',
            component: () => import( /* webpackChunkName: 'department-exam-officer-courses' */ "../components/Views/Department/ExamOffice/DepartmentalCourses")
        },
        {
            path: 'course-registrations',
            name: 'Department Exam Officer Course Registrations',
            component: () => import( /* webpackChunkName: 'department-exam-officer-course-registrations' */ '../components/Views/Department/ExamOffice/CoursesRegistrations')
        },
        {
            path: 'course-allocations',
            name: 'Department Exam Officer Course Allocations',
            component: () => import( /* webpackChunkName: 'department-exam-officer-course-allocation' */ '../components/Views/Department/ExamOffice/CourseAllocations')
        },
        {
            path: 'staff',
            name: 'Department Exam Officer Staff Members',
            component: () => import( /* webpackChunkName: 'department-exam-officer-staff-list' */ '../components/Views/Department/ExamOffice/Staff')
        },
        {
            path: 'students-registration-list',
            component: () => import( /* webpackChunkName: "department-exam-officer-students-registration-list-home" */ '../components/Views/Department/ExamOffice/StudentsRegistrationList/StudentsRegistration'),
            children: [
                {
                    path: '',
                    name: 'Department Exam Officer View Registration Submission List',
                    component: () => import( /* webpackChunkName: "department-exam-officer-students-registration-submission-list" */ '../components/Views/Department/ExamOffice/StudentsRegistrationList/StudentsRegistrationSubmissionList')
                },
                {
                    path: 'enrollment-list',
                    name: 'Department Exam Officer View Course Enrollment List',
                    component: () => import( /* webpackChunkName: "department-exam-officer-students-courses-enrollment-list" */ '../components/Views/Department/ExamOffice/StudentsRegistrationList/StudentsCoursesEnrollmentList')
                },
            ]
        },
    ]
},

{ //-------------------routings to the faculty offices------//beforeEnter: check if role is dean or subdean,-------------------//
    path: '/faculty',
    name: 'Faculty Dashboard',
    component: FacultyDashboard,
    beforeEnter: checkRole,
    meta: { authenticatedUserOnly: true },
    children: [{
        path: 'overview',
        name: 'Faculty Home',
        component: () => import( /* webpackChunkName: 'faculty-home' */ '../components/Views/Faculty/FacultyOverview')
    },
    {
        path: 'departments',
        name: 'Departments',
        component: () => import( /* webpackChunkName: 'faculty-departments-list' */ '../components/Views/Faculty/FacultyDepartments')
    },
    {
        path: 'staff',
        component: () => import( /* webpackChunkName: 'faculty-staff-list' */ '../components/Views/Faculty/Staff')
    },
    {
        path: 'hods',
        name: 'Head of Department',
        component: () => import( /* webpackChunkName: 'faculty-hods' */ '../components/Views/Faculty/HODs')
    },
    {
        path: 'academic-advisers',
        name: 'Faculty Academic Level Advisers',
        component: () => import( /* webpackChunkName: 'faculty-academic-advisers' */ '../components/Views/Faculty/LevelAdvisers')
    },
    {
        path: 'students',
        name: 'Faculty Students',
        component: () => import( /* webpackChunkName: 'faculty-students-list' */ '../components/Views/Faculty/Students')
    },
    {
        path: 'students-results',
        name: 'Faculty Students Results',
        component: () => import( /* webpackChunkName: 'faculty-students-results' */ '../components/Views/Faculty/StudentsResults')
    },

    {
        path: 'student-results/result',
        name: "Faculty Student's Result",
        component: () => import( /* webpackChunkName: 'faculty-students-result' */ '../components/Views/Faculty/StudentResults'),
        props: true
    },
    {
        path: 'programmes',
        name: 'Faculty Programmes',
        component: () => import( /* webpackChunkName: 'faculty-programmes-list' */ "../components/Views/Faculty/FacultyProgrammes")
    },
    {
        path: 'courses',
        name: 'Faculty Courses',
        component: () => import( /* webpackChunkName: 'faculty-courses' */ "../components/Views/Faculty/FacultyCourses")
    },

    {
        path: 'course-registrations',
        name: 'Faculty Course Registrations',
        component: () => import( /* webpackChunkName: 'faculty-course-registrations' */ '../components/Views/Faculty/CoursesRegistrations')
    },
    {
        path: 'extra-credit-applications',
        name: 'Extra Credit Applications',
        component: () => import( /* webpackChunkName: 'faculty-extra-credit-applications' */ '../components/Views/Faculty/ExtraCreditApplications')
    },
    {
        path: 'students-registration-list',
        component: () => import( /* webpackChunkName: "faculty-students-registration-list-home" */ '../components/Views/Faculty/StudentsRegistrationList/StudentsRegistration'),
        children: [
            {
                path: '',
                name: 'Faculty View Registration Submission List',
                component: () => import( /* webpackChunkName: "faculty-students-registration-submission-list" */ '../components/Views/Faculty/StudentsRegistrationList/StudentsRegistrationSubmissionList')
            },
            {
                path: 'enrollment-list',
                name: 'Faculty View Course Enrollment List',
                component: () => import( /* webpackChunkName: "faculty-students-courses-enrollment-list" */ '../components/Views/Faculty/StudentsRegistrationList/StudentsCoursesEnrollmentList')
            },
        ]
    },
    {
        path: 'exam-officers',
        name: 'Appoint Faculty Exam Officer',
        component: () => import( /* webpackChunkName: 'faculty-appoint-exam-officer' */ '../components/Views/Faculty/AppointExamOfficer')
    },
    {
        path: 'departments-overview',
        name: 'Departments Overview',
        component: () => import( /* webpackChunkName: 'departments-overview' */ "../components/Views/Faculty/AllDepartmentsOverview"),
    },
    {
        path: '/departments-overview/department/:id',
        name: 'Department Overview',
        component: () => import( /* webpackChunkName: 'department-overview' */ "../components/Views/Faculty/SelectedDepartmentOverview"),


    },
    {
        path: 'department-staff/:id',
        name: 'Selected Department Staff',
        component: () => import( /* webpackChunkName: 'selected-department-staff' */ '../components/Views/Faculty/Department/Staff')
    },
    {
        path: 'department-students/:id',
        name: 'Selected Department Students',
        component: () => import( /* webpackChunkName: 'selected-department-students' */ '../components/Views/Faculty/Department/Students')
    },
    {
        path: 'department-programmes/:id',
        name: 'Selected Department Programmes',
        component: () => import( /* webpackChunkName: 'selected-department-programmes' */ '../components/Views/Faculty/Department/Programmes')
    },
    {
        path: 'department-courses/:id',
        name: 'Selected Department Courses',
        component: () => import( /* webpackChunkName: 'selected-department-courses' */ '../components/Views/Faculty/Department/Courses')
    },

    ]
},

{ //-------------------routings to the faculty offices------//beforeEnter: check if role is dean or subdean,-------------------//
    path: '/faculty-exam-officer',
    name: 'Faculty Exam Officer Dashboard',
    component: FacultyExamOfficerDashboard,
    beforeEnter: checkRole,
    meta: { authenticatedUserOnly: true },
    children: [{
        path: 'overview',
        name: 'Faculty Exam Officer Home',
        component: () => import( /* webpackChunkName: 'faculty-exam-officer-home' */ '../components/Views/Faculty/ExamOffice/Overview')
    },
    {
        path: 'departments',
        name: 'Faculty Exam Officer Departments',
        component: () => import( /* webpackChunkName: 'faculty-exam-officer-departments-list' */ '../components/Views/Faculty/ExamOffice/FacultyDepartments')
    },
    {
        path: 'programmes',
        name: 'Faculty Exam Officer Programmes',
        component: () => import( /* webpackChunkName: 'faculty-exam-officer-programmes-list' */ "../components/Views/Faculty/ExamOffice/FacultyProgrammes")
    },
    {
        path: 'course-allocations',
        name: 'Faculty Exam Officer Course Allocations',
        component: () => import( /* webpackChunkName: 'faculty-exam-officer-course-allocation' */ '../components/Views/Faculty/ExamOffice/CourseAllocations')
    },
    {
        path: 'staff',
        name: 'Faculty Exam Officer Staff Members',
        component: () => import( /* webpackChunkName: 'faculty-exam-officer-staff-list' */ '../components/Views/Faculty/ExamOffice/Staff')
    },
    {
        path: 'hods',
        name: 'Faculty Exam Officer Head of Department',
        component: () => import( /* webpackChunkName: 'faculty-exam-officer-hods' */ '../components/Views/Faculty/ExamOffice/HODs')
    },
    {
        path: 'students',
        name: 'Faculty Exam Officer Students',
        component: () => import( /* webpackChunkName: 'faculty-exam-officer-students-list' */ '../components/Views/Faculty/ExamOffice/Students')
    },
    {
        path: 'students-results',
        name: 'Faculty Exam Officer Students Results',
        component: () => import( /* webpackChunkName: 'faculty-exam-officer-students-results' */ '../components/Views/Faculty/ExamOffice/StudentsResults')
    },

    {
        path: 'student-results/result',
        name: "Faculty Exam Officer Student's Result",
        component: () => import( /* webpackChunkName: 'faculty-exam-officer-students-result' */ '../components/Views/Faculty/ExamOffice/StudentResults'),
        props: true
    },
    {
        path: 'courses',
        name: 'Faculty Exam Officer Courses',
        component: () => import( /* webpackChunkName: 'faculty-exam-officer-courses' */ "../components/Views/Faculty/ExamOffice/FacultyCourses")
    },
    {
        path: 'course-registrations',
        name: 'Faculty Exam Officer Course Registrations',
        component: () => import( /* webpackChunkName: 'faculty-exam-officer-course-registrations' */ '../components/Views/Faculty/ExamOffice/CoursesRegistrations')
    },
    {
        path: 'students-registration-list',
        component: () => import( /* webpackChunkName: "faculty-exam-officer-students-registration-list-home" */ '../components/Views/Faculty/ExamOffice/StudentsRegistrationList/StudentsRegistration'),
        children: [
            {
                path: '',
                name: 'Faculty Exam Officer View Registration Submission List',
                component: () => import( /* webpackChunkName: "faculty-exam-officer-students-registration-submission-list" */ '../components/Views/Faculty/ExamOffice/StudentsRegistrationList/StudentsRegistrationSubmissionList')
            },
            {
                path: 'enrollment-list',
                name: 'Faculty Exam Officer View Course Enrollment List',
                component: () => import( /* webpackChunkName: "faculty-exam-officer-students-courses-enrollment-list" */ '../components/Views/Faculty/ExamOffice/StudentsRegistrationList/StudentsCoursesEnrollmentList')
            },
        ]
    },
    ]
},

{ //-------------------routings to the pre-admission screening pages------//beforeEnter: checkAdmissionOfficerRole,-------------------//
    path: '/institute',
    name: 'Institute',
    props: true,
    component: () => import( /* webpackChunkName: 'institutes' */ '../components/Views/Institutes/Dashboard'),
    meta: { authenticatedUserOnly: true },
    beforeEnter: checkSuperAdminOrDirectorRole,
    children: [
        {
            path: 'institute-dashboard',
            name: 'Institute Dashboard',
            component: () => import( /* webpackChunkName: 'institute-home' */ '../components/Views/Institutes/InstituteOverview'),
        },

        {
            path: 'academic_sessions',
            // name: 'Manage Sessions & Semseters',
            component: () => import( /* webpackChunkName: "semester-and-session-home" */ '../components/Views/Institutes/SessionsAndSemesters/SessionsAndSemesters'),
            children: [{
                path: '',
                name: 'Institute Manage Academic Sessions',
                component: () => import( /* webpackChunkName: "academic-sessions-list" */ '../components/Views/Institutes/SessionsAndSemesters/AcademicSessions'),
            },
            {
                path: "semesters",
                name: 'Institute Manage Semesters',
                component: () => import( /* webpackChunkName: "semesters-list" */ '../components/Views/Institutes/SessionsAndSemesters/Semesters'),
            },
            {
                path: 'session_semester_settings',
                name: 'Institute Session & Semseter Configurations',
                component: () => import( /* webpackChunkName: "session-semester-setting" */ '../components/Views/Institutes/SessionsAndSemesters/SessionAndSemesterSettings')
            },
            ]
        },

        {
            path: 'programmes',
            // name: 'Manage Academic Programmes & Courses',
            component: () => import( /* webpackChunkName: "programmes-and-course-home" */ '../components/Views/Institutes/ProgrammesAndCourses/ProgrammesAndCourses'),
            children: [{
                path: '',
                name: 'Institute Manage Academic Programmes',
                component: () => import( /* webpackChunkName: "mainstream-academic-programmes" */ '../components/Views/Institutes/ProgrammesAndCourses/ProgrammeList')
            },
            {
                path: "affiliate-programmes",
                name: 'Institute Manage Affiliate Academic Programmes',
                component: () => import( /* webpackChunkName: "affiliate-academic-programmes" */ '../components/Views/Institutes/ProgrammesAndCourses/AffiliateProgrammeList')
            },

            {
                path: "courses",
                name: 'Institute Manage Courses',
                component: () => import( /* webpackChunkName: "managecourse-list" */ '../components/Views/Institutes/ProgrammesAndCourses/CourseList')
            },

            {
                path: "credit-load-limit",
                name: 'Institute Manage Credit Load Limit',
                component: () => import( /* webpackChunkName: "manage-credit-load-limit-list" */ '../components/Views/Institutes/ProgrammesAndCourses/CreditLoadLimitList')
            },
            ]
        },

        {
            path: 'users',
            component: () => import( /* webpackChunkName: "users-home" */ '../components/Views/Institutes/Users/UsersManagement'),
            children: [{
                path: "",
                name: 'Manage Institute Students',
                component: () => import( /* webpackChunkName: "users-institutes-students" */ '../components/Views/Institutes/Users/StudentManagement')
            },
            {
                path: "lecturers",
                name: 'Manage Institute Lecturers',
                component: () => import( /* webpackChunkName: "users-institutes-lecturers" */ '../components/Views/Institutes/Users/LecturerManagement')
            },
            {
                path: "supporting-staff",
                name: 'Manage Institute Supporting Staff',
                component: () => import( /* webpackChunkName: "users-institutes-supporting-staff" */ '../components/Views/Institutes/Users/SupportStaffManagement'),
            },
            ]
        },

        {
            path: 'units',
            name: "Manage Institute Units",
            component: () => import( /* webpackChunkName: "institutes-units" */  '../components/Views/Institutes/Unit/Units')
        },

        {
            path: 'unit/profile',
            name: 'Unit Profile',
            component: () => import( /* webpackChunkName: "institute-unit-profile" */ '../components/Views/Institutes/Unit/UnitProfile'),
            props: true,
        },

        {
            path: 'degree-categories',
            name: "Manage Institute Academic Degrees",
            component: () => import( /* webpackChunkName: "institutes-academic-degree-categories" */  '../components/Views/Institutes/AcademicDegrees/AcademicDegreeCategory')
        },

        {
            path: 'academic-levels',
            // name: 'Students & Academic Programmes Levels',
            component: () => import( /* webpackChunkName: "institutes-academic-levels-home" */  '../components/Views/Institutes/AcademicLevels/Levels'),
            children: [
                {
                    path: "",
                    name: 'Manage Institute Academic Programme Levels',
                    component: () => import( /* webpackChunkName: "institutes-program-academic-levels" */  '../components/Views/Institutes/AcademicLevels/AcademicProgrammeLevels'),
                },
                {
                    path: 'student-levels',
                    name: 'Manage Institute Students Academic Levels',
                    component: () => import( /* webpackChunkName: "institutes-student-academic-levels" */  '../components/Views/Institutes/AcademicLevels/StudentAcademicLevels')
                },
                {
                    path: 'students-level-progression',
                    name: 'Students Institute Academic Level Progression',
                    component: () => import( /* webpackChunkName: "institutes-student-level-progression" */  '../components/Views/Institutes/AcademicLevels/StudentAcademicLevelProgression')
                },
            ]
        },

        {
            path: 'announcements',
            component: () => import( /* webpackChunkName: "institutes-manage-announcement-list" */ '../components/Views/Institutes/Announcements/AnnouncementsList'),
            name: 'Manage Institute Announcement',
        },

        {
            path: 'students-registration-list',
            component: () => import( /* webpackChunkName: "institutes-students-registration-list-home" */ '../components/Views/Institutes/StudentsRegistrationList/StudentsRegistration'),
            children: [
                {
                    path: '',
                    name: 'Manage Institute Students Registration',
                    component: () => import( /* webpackChunkName: "institutes-students-registration-list" */ '../components/Views/Institutes/StudentsRegistrationList/StudentsRegistrationList')
                },
                {
                    path: 'submission-list',
                    name: 'View Institute Registration Submission List',
                    component: () => import( /* webpackChunkName: "institutes-students-registration-submission-list" */ '../components/Views/Institutes/StudentsRegistrationList/StudentsRegistrationSubmissionList')
                },
                {
                    path: 'enrollment-list',
                    name: 'View Institute Course Enrollment List',
                    component: () => import( /* webpackChunkName: "institutes-students-courses-enrollment-list" */ '../components/Views/Institutes/StudentsRegistrationList/StudentsCoursesEnrollmentList')
                },
            ]
        },
    ]

},

{
    path: '/institute/admissions',
    component: () => import( /* webpackChunkName: 'institute-admissions' */ '../components/Views/Institutes/Admissions/Dashboard'),
    props: true,
    children: [
        {
            path: 'institute-admissions-dashboard',
            name: 'Institute Admissions Dashboard',
            component: () => import( /* webpackChunkName: 'institute-admissions-home' */ '../components/Views/Institutes/Admissions/Overview'),
        },
        {
            path: 'candidates',
            component: () => import( /* webpackChunkName: 'institute-candidates-list' */ '../components/Views/Institutes/Admissions/Candidates'),
            name: 'Institute Admission Candidates',
        },
        {
            path: 'entry-modes',
            component: () => import( /* webpackChunkName: 'institute-modes-and-catchments-list' */ '../components/Views/Institutes/Admissions/ModesHome'),
            children: [{
                path: "",
                name: 'Institute Modes of Entry',
                component: () => import( /* webpackChunkName: 'institute-modes-of-entry-list' */ '../components/Views/Institutes/Admissions/ModesOfEntry')
            },
            {
                path: "catchment-states",
                name: 'Institute Cathcment States',
                component: () => import( /* webpackChunkName: 'institute-cathcment-states-list' */ '../components/Views/Institutes/Admissions/Catchments'),
            },
            ]
        },
        {
            path: 'exam-bodies',
            component: () => import( /* webpackChunkName: 'institute-exam-bodies-and-type' */ '../components/Views/Institutes/Admissions/ExamsHome'),
            children: [{
                path: "",
                name: 'Institute Approved Examination Bodies',
                component: () => import( /* webpackChunkName: 'institute-exam-bodies-list' */ '../components/Views/Institutes/Admissions/ExamBodies')
            },
            {
                path: "exam-types",
                name: 'Institute Examination Types',
                component: () => import( /* webpackChunkName: 'institute-exam-types-list' */ '../components/Views/Institutes/Admissions/ExamTypes'),
            },
            ]
        },
        {
            path: 'post-utme-exams',
            component: () => import( /* webpackChunkName: 'institute-exam-cities-and-centres' */ '../components/Views/Institutes/Admissions/PostUtmeHome'),
            children: [{
                path: "",
                name: 'Institute Post UTME Examination',
                component: () => import( /* webpackChunkName: 'institute-post-utme-exams-list' */ '../components/Views/Institutes/Admissions/PostUtmeExams')
            },
            {
                path: "exam-cities",
                name: 'Institute Examination Cities',
                component: () => import( /* webpackChunkName: 'institute-exam-cities-list' */ '../components/Views/Institutes/Admissions/ExamCities'),
            },
            {
                path: "exam-centres",
                name: 'Institute Examination Centres',
                component: () => import( /* webpackChunkName: 'institute-exam-centres-list' */ '../components/Views/Institutes/Admissions/ExamCentres'),
            },
            {
                path: "batches",
                name: 'Institute Post UTME Examination Batches',
                component: () => import( /* webpackChunkName: 'institute-post-utme-exam-batches-list' */ '../components/Views/Institutes/Admissions/PostUtmeExamBatches'),
            },
            {
                path: "post-utme-exam/batch",
                name: "Institute Post Utme Exam Batch",
                component: () =>
                    import(
                            /* webpackChunkName: 'institute-post-utme-exam-batch' */ "../components/Views/Institutes/Admissions/PostUtmeExamBatch"
                    ),
                props: true,
            },
            {
                path: "allocations",
                name: 'Institute Post UTME Examination Allocations',
                component: () => import( /* webpackChunkName: 'institute-post-utme-exam-allocations-list' */ '../components/Views/Institutes/Admissions/PostUtmeExamAllocations'),
            },
            ]
        },

        {
            path: 'grades',
            name: "Institute O'Level Grades",
            component: () => import( /* webpackChunkName: 'institute-olevel-grades-list' */ '../components/Views/Institutes/Admissions/Grades'),
        },

        {
            path: 'admission-acceptance-deadlines',
            name: "Institute Admission Acceptance Deadlines",
            component: () => import( /* webpackChunkName: 'institute-admission-acceptance-deadlines' */ '../components/Views/Institutes/Admissions/AdmissionAcceptanceDeadlines'),
        },

        {
            path: 'admission-rounds',
            name: "Institute Admissions Rounds",
            component: () => import( /* webpackChunkName: 'institute-admissions-rounds-list' */ '../components/Views/Institutes/Admissions/AdmissionRounds'),
        },
        {
            path: 'advertised-programmes',
            name: "Institute Advertised Programmes",
            component: () => import( /* webpackChunkName: 'institute-advertised-programmes-list' */ '../components/Views/Institutes/Admissions/Programmes'),
        },

        {
            path: 'admission-clearance',
            name: "Institute Admission Clearance",
            component: () => import( /* webpackChunkName: 'institute-admission-clearance' */ '../components/Views/Institutes/Admissions/ProgrammesWithAdmittedCandidates'),
        },
        {
            path: 'requirements/general',
            name: "Institute General Admission Requirements",
            component: () => import( /* webpackChunkName: 'institute-institutional-general-admission-requirements' */ '../components/Views/Institutes/Admissions/GeneralRequirements'),
            props: true
        },
        {
            path: 'requirements/programme',
            name: "Institute Programme Admission Requirements",
            component: () => import( /* webpackChunkName: 'institute-programme-admission-requirements' */ '../components/Views/Institutes/Admissions/ProgrammeRequirements'),
            props: true
        },
        {
            path: 'programmes/candidates',
            name: "Institute Programme Candidates",
            component: () => import( /* webpackChunkName: 'institute-programme-candidates-list' */ '../components/Views/Institutes/Admissions/ProgrammeCandidates'),
            props: true
        },

        {
            path: 'clearance-candidates',
            name: "Institute Clearance Candidates",
            component: () => import( /* webpackChunkName: 'institute-clearance-candidates-list' */ '../components/Views/Institutes/Admissions/ClearanceCandidates'),
            props: true
        },

        {
            path: 'programmes/candidates/application-details',
            name: "Institute Candidate's Application Details",
            component: () => import( /* webpackChunkName: 'institute-candidates-application-details' */ '../components/Views/Institutes/Admissions/CandidateApplicationDetails'),
            props: true
        },

        {
            path: 'clearance-candidates/clearance-documents',
            name: "Institute Candidate's Clearance Documents",
            component: () => import( /* webpackChunkName: 'institute-candidates-application-clearance-details' */ '../components/Views/Institutes/Admissions/CandidateClearanceDocuments'),
            props: true
        },
        {
            path: "admissions-list",
            name: 'Institute Upload Admission List',
            component: () => import( /* webpackChunkName: 'institute-admissions-list' */ '../components/Views/Admin/Admissions/UploadAdmissionList'),
        },
    ]
},

{
    //-------------------routings to the finance pages-----------------------//
    path: "/institute/finance",
    name: "Institute Finance",
    props: true,
    component: () =>
        import(
            /* webpackChunkName: 'finance' */ "../components/Views/Institutes/Finance/Dashboard"
        ),
    meta: { authenticatedUserOnly: true },
    children: [
        {
            path: "finance-dashboard",
            name: "Institute Finance Dashboard",
            component: () =>
                import(
                    /* webpackChunkName: 'finance-home' */ "../components/Views/Institutes/Finance/FinanceOverview"
                ),
        },
        {
            path: "payments",
            name: "Institute Payments",
            component: () =>
                import(
                    /* webpackChunkName: 'payments-lists' */ "../components/Views/Institutes/Finance/Payments"
                ),
        },
        {
            path: 'charges',
            name: "Institute Admission Processing Charges",
            component: () => import( /* webpackChunkName: 'admission-processing-charges' */ '../components/Views/Institutes/Finance/ProcessingCharges'),
        },
        {
            path: "invoices",
            name: "Institute Invoices",
            component: () =>
                import(
                    /* webpackChunkName: 'invoice-lists' */ "../components/Views/Institutes/Finance/Invoices"
                ),
        },
        {
            path: "fees",
            component: () => import( /* webpackChunkName: "fees" */ '../components/Views/Institutes/Finance/FeesAndFeesAllocation'),
            //props: true,
            children: [{
                path: "",
                name: 'Institute Fee Items',
                component: () => import( /* webpackChunkName: "fee-items" */ '../components/Views/Institutes/Finance/FeeItems')
            },
            {
                path: "fee-plans",
                name: 'Institute Fee Plans',
                component: () => import( /* webpackChunkName: "fee-plans" */ '../components/Views/Institutes/Finance/Feeplans')
            },
            {
                path: "fee-plan-allocation",
                name: 'Institute Fee Plan Allocation',
                component: () => import( /* webpackChunkName: "fee-plan-allocation" */ '../components/Views/Institutes/Finance/FeePlanAllocation')
            },
            ]
        },
        {
            path: 'fee-plan-items',
            name: "Institute Fee Plan Items",
            component: () => import( /* webpackChunkName: 'fee-plan-items' */ '../components/Views/Institutes/Finance/FeePlanItems'),
            props: true,
        },
        {
            path: "bank-accounts",
            name: "Institute Bank Accounts",
            component: () =>
                import(
                    /* webpackChunkName: 'bank-accounts' */ "../components/Views/Institutes/Finance/BankAccounts"
                ),
        },
        {
            path: 'transaction-validation',
            name: 'Institute Students Transactions Validation',
            component: () => import(/* webpackChunkName: 'transaction-validation' */ '../components/Views/Institutes/Finance/PaymentsValidation'),
        },
    ],
},


{ //-------------------routings to the grading system pages-----------------------//
    path: '/grading-system',
    name: 'Grading System',
    component: () => import( /* webpackChunkName: 'grading-system' */ '../components/Views/Admin/GradingSystem/Dashboard'),
    meta: { authenticatedUserOnly: true },
    children: [
        {
            path: 'grading-system-dashboard',
            name: 'Grading System Dashboard',
            component: () => import( /* webpackChunkName: 'grading-home' */ '../components/Views/Admin/GradingSystem/GradingOverview'),
        },
        {
            path: "grade-profiles",
            name: 'Manage Grade Profiles',
            component: () => import( /* webpackChunkName: "grade-profiles" */ '../components/Views/Admin/GradingSystem/GradeProfiles'),
        },
        {
            path: "grade-profiles-allocation",
            name: 'Manage Grade Profiles Allocations',
            component: () => import( /* webpackChunkName: "grade-profiles-allocation" */ '../components/Views/Admin/GradingSystem/GradeProfilesAllocations'),
        },
        {
            path: "grade-list",
            name: 'Manage Grade List',
            component: () => import( /* webpackChunkName: "grade-lists" */ '../components/Views/Admin/GradingSystem/GradeList'),
        },
        {
            path: "grade-profile-exception-list",
            name: 'Manage Grade Profile Exception List',
            component: () => import( /* webpackChunkName: "grade-profile-exception-lists" */ '../components/Views/Admin/GradingSystem/GradProfileExceptionList'),
        },
        {
            path: "assessment_policy",
            name: 'Manage Assessment Policy',
            component: () => import( /* webpackChunkName: "assessment-policy" */ '../components/Views/Admin/Assessment/AssessmentWithPolicy'),
        },
        {
            path: "assessment-score",
            name: 'Assessment Score Upload',
            component: () => import( /* webpackChunkName: "assessment-score" */ '../components/Views/Admin/Assessment/AssessmentScore'),
        },
        {
            path: "grade-point-average-profiles",
            name: 'Manage Grade Point Average Profiles',
            component: () => import( /* webpackChunkName: "grade-point-average-profiles" */ '../components/Views/Admin/GradingSystem/GradePointAverageProfiles'),
        },
        {
            path: "grade-lock",
            name: 'Toggle Grade Lock',
            component: () => import( /* webpackChunkName: "grade-lock" */ '../components/Views/Admin/GradingSystem/GradeLock'),
        },
        {
            path: 'students-result-list',
            component: () => import( /* webpackChunkName: "students-result-list-home" */ '../components/Views/Admin/GradingSystem/StudentsResult'),
            children: [
                {
                    path: '',
                    name: 'Manage Students Result',
                    component: () => import( /* webpackChunkName: "students-result-list" */ '../components/Views/Admin/GradingSystem/Results')
                },
                {
                    path: 'cummulative-result-list',
                    name: 'View Cummulative Result',
                    component: () => import( /* webpackChunkName: "cummulative-result-list" */ '../components/Views/Admin/GradingSystem/CummulativeResult')
                },
                {
                    path: 'spreadsheet-list',
                    name: 'View Student Result Spreadsheet List',
                    component: () => import( /* webpackChunkName: "spreadsheet-list" */ '../components/Views/Admin/GradingSystem/ResultSpreadSheet')
                },
                {
                    path: 'results-spreadsheet',
                    component: () => import( /* webpackChunkName: "results-spreadsheet" */ '../components/Views/Admin/GradingSystem/StudentResultsSpreadSheet'),
                    name: 'Admin Student Results Spread Sheet',
                },
            ]
        },
        {
            path: "degree-class",
            name: 'Manage Degree Class List',
            component: () => import( /* webpackChunkName: "degree-class" */ '../components/Views/Admin/GradingSystem/DegreeClass'),
        },

        {
            path: "grade-point-average-profiles-allocation",
            component: () => import( /* webpackChunkName: "gpa-profile-allocation-home" */ '../components/Views/Admin/GradingSystem/GradePointProfileAllocation/GradePointProfileAllocationsHome'),
            //props: true,
            children: [{
                path: "",
                name: 'Default Grade Point Average Profiles Allocations',
                component: () => import( /* webpackChunkName: "default-gpa-profile-allocation" */ '../components/Views/Admin/GradingSystem/GradePointProfileAllocation/DefaultGradePointAverageProfilesAllocations')
            },
            {
                path: "sessional-grade-point-average-profiles-allocation",
                name: 'Sessional Grade Point Average Profiles Allocations',
                component: () => import( /* webpackChunkName: "sessional-grade-point-average-profiles-allocation" */ '../components/Views/Admin/GradingSystem/GradePointAverageProfilesAllocations'),
            },
            ]
        },
        {
            path: 'add-assessment/:course_id/:session_id/:semester_id',
            component: () => import(/* webpackChunkName: 'add-assessment' */ '../components/Views/Lecturer/AddAssessment'),
            name: "Add New Assessment"
        },
    ]

},

{
    path: "/admissions/programme/requirements",
    name: "Programme Requirements",
    component: () =>
        import(
                /* webpackChunkName: 'programme-requirements' */ "../components/Views/Admissions/ProgrammeRequirements"
        ),
    props: true,
},
{
    //-------------------routings to the pre-admission screening pages------//beforeEnter: checkAdmissionOfficerRole,-------------------//
    path: "/admissions",
    name: "Admissions",
    component: () =>
        import(
                /* webpackChunkName: 'admissions' */ "../components/Views/Admissions/Dashboard"
        ),
    meta: { authenticatedUserOnly: true },
    beforeEnter: checkAdmissionsDashboardPermission,
    children: [
        {
            path: "admissions-dashboard",
            name: "Admissions Dashboard",
            component: () =>
                import(
                        /* webpackChunkName: 'admissions-home' */ "../components/Views/Admissions/Overview"
                ),
        },
        {
            path: "candidates",
            name: "Admission Candidates",
            component: () =>
                import(
                        /* webpackChunkName: 'candidates-list' */ "../components/Views/Admissions/Candidates"
                ),
        },
        {
            path: "entry-modes",
            component: () =>
                import(
                        /* webpackChunkName: 'modes-and-catchments-list' */ "../components/Views/Admissions/ModesHome"
                ),
            children: [
                {
                    path: "",
                    name: "Modes of Entry",
                    component: () =>
                        import(
                                /* webpackChunkName: 'modes-of-entry-list' */ "../components/Views/Admissions/ModesOfEntry"
                        ),
                },
                {
                    path: "catchment-states",
                    name: "Cathcment States",
                    component: () =>
                        import(
                                /* webpackChunkName: 'cathcment-states-list' */ "../components/Views/Admissions/Catchments"
                        ),
                },
            ],
        },
        {
            path: "exam-bodies",
            component: () =>
                import(
                        /* webpackChunkName: 'exam-bodies-and-type' */ "../components/Views/Admissions/ExamsHome"
                ),
            children: [
                {
                    path: "",
                    name: "Approved Examination Bodies",
                    component: () =>
                        import(
                                /* webpackChunkName: 'exam-bodies-list' */ "../components/Views/Admissions/ExamBodies"
                        ),
                },
                {
                    path: "exam-types",
                    name: "Examination Types",
                    component: () =>
                        import(
                                /* webpackChunkName: 'exam-types-list' */ "../components/Views/Admissions/ExamTypes"
                        ),
                },
            ],
        },

        {
            path: 'post-utme-exams',
            component: () => import( /* webpackChunkName: 'exam-cities-and-centres' */ '../components/Views/Admissions/PostUtmeHome'),
            children: [{
                path: "",
                name: 'Post UTME Examination',
                component: () => import( /* webpackChunkName: 'post-utme-exams-list' */ '../components/Views/Admissions/PostUtmeExams')
            },
            {
                path: "exam-cities",
                name: 'Examination Cities',
                component: () => import( /* webpackChunkName: 'exam-cities-list' */ '../components/Views/Admissions/ExamCities'),
            },
            {
                path: "exam-centres",
                name: 'Examination Centres',
                component: () => import( /* webpackChunkName: 'exam-centres-list' */ '../components/Views/Admissions/ExamCentres'),
            },
            {
                path: "batches",
                name: 'Post UTME Examination Batches',
                component: () => import( /* webpackChunkName: 'post-utme-exam-batches-list' */ '../components/Views/Admissions/PostUtmeExamBatches'),
            },
            {
                path: "post-utme-exam/batch",
                name: "Post Utme Exam Batch",
                component: () =>
                    import(
                            /* webpackChunkName: 'post-utme-exam-batch' */ "../components/Views/Admissions/PostUtmeExamBatch"
                    ),
                props: true,
            },
            {
                path: "allocations",
                name: 'Post UTME Examination Allocations',
                component: () => import( /* webpackChunkName: 'post-utme-exam-allocations-list' */ '../components/Views/Admissions/PostUtmeExamAllocations'),
            },
            ]
        },

        {
            path: "grades",
            name: "O'Level Grades",
            component: () =>
                import(
                        /* webpackChunkName: 'olevel-grades-list' */ "../components/Views/Admissions/Grades"
                ),
        },
        {
            path: "grades/a_level",
            name: "A'Level Grades",
            component: () =>
                import(
                        /* webpackChunkName: 'alevel-grades-list' */ "../components/Views/Admissions/AGrades"
                ),
        },

        {
            path: "grades/degree",
            name: "Degree / Diploma Grades",
            component: () =>
                import(
                        /* webpackChunkName: 'degree-grades-list' */ "../components/Views/Admissions/DegreeGrades"
                ),
        },
        // {
        //     path: "charges",
        //     name: "Admission Processing Charges",
        //     component: () =>
        //         import(
        //             /* webpackChunkName: 'admission-processing-charges' */ "../components/Views/Admissions/ProcessingCharges"
        //         ),
        // },

        {
            path: "admission-acceptance-deadlines",
            name: "Admission Acceptance Deadlines",
            component: () =>
                import(
                        /* webpackChunkName: 'admission-acceptance-deadlines' */ "../components/Views/Admissions/AdmissionAcceptanceDeadlines"
                ),
        },

        {
            path: "admission-rounds",
            name: "Admissions Rounds",
            component: () =>
                import(
                        /* webpackChunkName: 'admissions-rounds-list' */ "../components/Views/Admissions/AdmissionRounds"
                ),
        },
        {
            path: "advertised-programmes-faculties",
            name: "Advertised Programmes Faculties",
            component: () =>
                import(/* webpackChunkName: 'advertised-programmes-faculties-list' */ "../components/Views/Admissions/Faculties"),
        },
        {
            path: "advertised-programmes",
            name: "Advertised Programmes",
            props: true,
            component: () =>
                import(
                        /* webpackChunkName: 'advertised-programmes-list' */ "../components/Views/Admissions/Programmes"
                ),
        },
        {
            path: "admission-clearance-faculties",
            name: "Admission Clearance Faculties",
            component: () =>
                import(/* webpackChunkName: 'admissions-clearance-faculties-list' */ "../components/Views/Admissions/AdmissionClearanceFaculties"),
        },
        {
            path: "admission-clearance",
            name: "Admission Clearance",
            props: true,
            component: () =>
                import(
                        /* webpackChunkName: 'admission-clearance' */ "../components/Views/Admissions/ProgrammesWithAdmittedCandidates"
                ),
        },

        // {
        //     path: "post-utme-exam/batch",
        //     name: "Post Utme Exam Batch",
        //     component: () =>
        //         import(
        //                 /* webpackChunkName: 'institutional-post-utme-exam-batch' */ "../components/Views/Admissions/PostUtmeExamBatch"
        //         ),
        //     props: true,
        // },

        {
            path: "requirements/general",
            name: "General Admission Requirements",
            component: () =>
                import(
                        /* webpackChunkName: 'institutional-general-admission-requirements' */ "../components/Views/Admissions/GeneralRequirements"
                ),
            props: true,
        },
        {
            path: "requirements/programme",
            name: "Programme Admission Requirements",
            component: () =>
                import(
                        /* webpackChunkName: 'programme-admission-requirements' */ "../components/Views/Admissions/ProgrammeRequirements"
                ),
            props: true,
        },
        {
            path: "programmes/candidates",
            name: "Programme Candidates",
            component: () =>
                import(
                        /* webpackChunkName: 'programme-candidates-list' */ "../components/Views/Admissions/ProgrammeCandidates"
                ),
            props: true,
        },

        {
            path: "clearance-candidates",
            name: "Clearance Candidates",
            component: () =>
                import(
                        /* webpackChunkName: 'clearance-candidates-list' */ "../components/Views/Admissions/ClearanceCandidates"
                ),
            props: true,
        },

        {
            path: "programmes/candidates/application-details",
            name: "Candidate's Application Details",
            component: () =>
                import(
                        /* webpackChunkName: 'candidates-application-details' */ "../components/Views/Admissions/CandidateApplicationDetails"
                ),
            props: true,
        },

        {
            path: "clearance-candidates/clearance-documents",
            name: "Candidate's Clearance Documents",
            component: () =>
                import(
                        /* webpackChunkName: 'candidates-application-clearance-details' */ "../components/Views/Admissions/CandidateClearanceDocuments"
                ),
            props: true,
        },
        {
            path: "admissions-list",
            name: 'Upload Admission List',
            component: () => import( /* webpackChunkName: 'admissions-list' */ '../components/Views/Admin/Admissions/UploadAdmissionList'),
        },
    ],
},

//-------------------------------------

{
    //-------------------routings to the finance pages-----------------------//
    path: "/finance",
    name: "Finance",
    component: () =>
        import(
                /* webpackChunkName: 'finance' */ "../components/Views/Admin/Finance/Dashboard"
        ),
    meta: { authenticatedUserOnly: true },
    children: [
        {
            path: "finance-dashboard",
            name: "Finance Dashboard",
            component: () =>
                import(
                        /* webpackChunkName: 'finance-home' */ "../components/Views/Admin/Finance/FinanceOverview"
                ),
        },
        {
            path: "payments",
            name: "Payments",
            component: () =>
                import(
                        /* webpackChunkName: 'payments-lists' */ "../components/Views/Admin/Finance/Payments"
                ),
        },
        {
            path: 'charges',
            name: "Admission Processing Charges",
            component: () => import( /* webpackChunkName: 'admission-processing-charges' */ '../components/Views/Admissions/ProcessingCharges'),
        },
        {
            path: "invoices",
            name: "Invoices",
            component: () =>
                import(
                        /* webpackChunkName: 'invoice-lists' */ "../components/Views/Admin/Finance/Invoices"
                ),
        },
        {
            path: "fees",
            //  name: "Fees",
            component: () => import( /* webpackChunkName: "fees" */ '../components/Views/Admin/Finance/FeesAndFeesAllocation'),
            //props: true,
            children: [{
                path: "",
                name: 'Fee Items',
                component: () => import( /* webpackChunkName: "fee-items" */ '../components/Views/Admin/Finance/FeeItems')
            },
            {
                path: "fee-plans",
                name: 'Fee Plans',
                component: () => import( /* webpackChunkName: "fee-plans" */ '../components/Views/Admin/Finance/Feeplans')
            },
            {
                path: "fee-plan-allocation",
                name: 'Fee Plan Allocation',
                component: () => import( /* webpackChunkName: "fee-plan-allocation" */ '../components/Views/Admin/Finance/FeePlanAllocation')
            },
            ]
        },
        {
            path: "rebates",
            component: () => import( /* webpackChunkName: "rebates" */ '../components/Views/Admin/Finance/Rebates/RebatesHome'),
            //props: true,
            children: [{
                path: "",
                name: 'Rebates',
                component: () => import( /* webpackChunkName: "rebates" */ '../components/Views/Admin/Finance/Rebates/RebatesComponent'),
            },
            {
                path: "rebates-grant",
                name: 'Rebates Grant',
                component: () => import( /* webpackChunkName: "rebates-grant" */ '../components/Views/Admin/Finance/Rebates/RebatesGrantComponent'),
            },

            {
                path: "scholarships",
                name: 'Scholarships',
                component: () => import( /* webpackChunkName: "scholarships" */ '../components/Views/Admin/Finance/Rebates/Scholarships'),
            },
            {
                path: "scholarship-grants",
                name: 'Scholarships Grant',
                component: () => import( /* webpackChunkName: "scholarship-grant" */ '../components/Views/Admin/Finance/Rebates/ScholarshipGrants'),
            },
            ]
        },
        {
            path: 'fee-plan-items',
            name: "Fee Plan Items",
            component: () => import( /* webpackChunkName: 'fee-plan-items' */ '../components/Views/Admin/Finance/FeePlanItems'),
            props: true,
        },
        {
            path: "bank-accounts",
            name: "Bank Accounts",
            component: () =>
                import(
                        /* webpackChunkName: 'bank-accounts' */ "../components/Views/Admin/Finance/BankAccounts"
                ),
        },
        {
            path: 'transaction-validation',
            name: 'Students Transactions Validation',
            component: () => import(/* webpackChunkName: 'transaction-validation' */ '../components/Views/Admin/Finance/PaymentsValidation'),
        },

        {
            path: 'hostel-pool-charges',
            name: "Manage Hostel Pool",
            component: () => import( /* webpackChunkName: 'hostel-pool-charges' */ '../components/Views/Admin/Finance/HostelPoolCharges'),
        },

        {
            path: 'change-programme-charges',
            name: "Change of Programme Charges",
            component: () => import( /* webpackChunkName: 'change-programme-charges' */ '../components/Views/Admin/Finance/ChangeOfProgrammeCharges.vue'),
        },

        {
            path: 'convocation-charges',
            name: "Manage Convocation Charges",
            component: () => import( /* webpackChunkName: 'convocation-charges' */ '../components/Views/Admin/Finance/ConvocationCharges'),
        },
    ],
},
//------------------- candidate guarded routings -----------------
{
    path: "/candidate/admission/processing-charges",
    name: "Candidate Charges",
    component: () =>
        import(
                /* webpackChunkName: 'candidate-admission-processing-charges' */ "../components/Views/Candidate/Undergraduate/Fees/PreadmissionAppliactionProcessingCharges"
        ),
    props: true,
    // meta: { authenticatedUserOnly: true },
    //beforeEnter: checkCandidateRole,
},

{
    path: "/candidate/admission/processing-charges/private",
    name: "Private Candidate Charges",
    component: () =>
        import(
                /* webpackChunkName: 'private-candidate-admission-processing-charges' */ "../components/Views/Candidate/Undergraduate/Fees/PrivatePreadmissionAppliactionProcessingCharges"
        ),
    props: true,
},

{
    path: '/candidate',
    name: 'Candidate',
    component: () => import( /* webpackChunkName: 'candidate-dashboard' */ '../components/Views/Candidate/Dashboard'),
    meta: { authenticatedUserOnly: true },
    beforeEnter: checkCandidateRole,
    children: [

        //pg personal information
        {
            path: 'pg/application/:id',
            name: "Admission Application",
            component: () => import( /* webpackChunkName: 'admission-application' */ '../components/Views/Candidate/Postgraduate/Application'),

        },

        //ug personal information
        {
            path: 'utme/application',
            name: "UTME Application",
            component: () => import( /* webpackChunkName: 'utme-application' */ '../components/Views/Candidate/Undergraduate/UTMEApplication'),
        },

        {
            path: 'announcements',
            name: "Candidate Announcements",
            component: () => import( /* webpackChunkName: 'candidate-announcements' */ '../components/Views/Candidate/Announcements'),
        },

        {
            path: 'admission/clearance-documents',
            name: "Clearance Documents",
            component: () => import( /* webpackChunkName: 'clearance-documents' */ '../components/Views/Candidate/Undergraduate/ClearanceDocuments'),
        },

        {
            path: 'bio-data',
            name: "Candidate Biodata",
            component: () => import( /* webpackChunkName: 'candidate-biodata' */ '../components/Views/Candidate/Undergraduate/BioData'),
            // props: true
        },
        {
            path: 'results/o-level',
            name: "Candidate OLevels",
            component: () => import( /* webpackChunkName: 'candidate-olevels' */ '../components/Views/Candidate/Undergraduate/Olevels'),
            // props: true
        },
        {
            path: 'admission/status',
            name: "Candidate Admission Status",
            component: () => import( /* webpackChunkName: 'candidate-admission-status' */ '../components/Views/Candidate/Undergraduate/AdmissionStatus'),
            // props: true
        },

        {
            path: 'admission/acceptance-fees',
            name: "candidate.payAdmissionAcceptanceFee",
            component: () => import( /* webpackChunkName: 'pay-admission-acceptance-fees' */ '../components/Views/Candidate/Undergraduate/Fees/PayAdmissionAcceptanceFee'),
        },


        {
            path: 'printouts',
            name: "Candidate Printouts",
            component: () => import( /* webpackChunkName: 'admission-application-printouts' */ '../components/Views/Candidate/PrintOuts'),

        },

        {
            path: 'migrate-to-student',
            name: "Migrate Candidate to Student",
            component: () => import( /* webpackChunkName: 'migrate-candidate-to-student' */ '../components/Views/Candidate/Undergraduate/MigrateCandidateToStudent'),

        },
    ],
},
{
    path: '*',
    // name: 'Page Not Found',
    component: () => import( /* webpackChunkName: 'page-not-found' */ '../components/PageNotFound')
},

{ //-------------------routings to the hostel management pages-----------------------//
    path: '/hostel',
    name: 'Hostel',
    component: () => import( /* webpackChunkName: 'hostel' */ '../components/Views/Admin/HostelManagement/Dashboard'),
    meta: { authenticatedUserOnly: true },
    children: [

        {
            path: 'hostel-dashboard',
            name: 'Hostel Management Dashboard',
            component: () => import( /* webpackChunkName: 'hostel-dashboard' */ '../components/Views/Admin/HostelManagement/HostelOverview'),
        },
        {
            path: "hostels",
            //name: 'Manage Hostels And Hostel Blocks',
            component: () => import( /* webpackChunkName: "hostels-and-hostel-blocks" */ '../components/Views/Admin/HostelManagement/HostelsAndHostelBlocks'),
            //props: true,
            children: [{
                path: "",
                name: 'Manage Hostels',
                component: () => import( /* webpackChunkName: "manage-hostels" */ '../components/Views/Admin/HostelManagement/Hostels')
            },
            {
                path: "hostel-blocks",
                name: 'Manage Hostel Blocks',
                component: () => import( /* webpackChunkName: "hostel-blocks" */ '../components/Views/Admin/HostelManagement/HostelBlocks')
            },
            ]
        },
        {
            path: 'hostel-block-allocation',
            name: 'Hostel Block Allocations',
            component: () => import( /* webpackChunkName: 'hostel-block-allocation' */ '../components/Views/Admin/HostelManagement/HostelBlockAllocation'),

        },
        {
            path: "rooms",
            //name: 'Manage Hostel Rooms And Bedspaces',
            component: () => import( /* webpackChunkName: "hostel-rooms-and-bedspaces" */ '../components/Views/Admin/HostelManagement/RoomsAndBedspaces'),
            //props: true,
            children: [{
                path: "",
                name: 'Manage Hostel Rooms',
                component: () => import( /* webpackChunkName: "manage-rooms" */ '../components/Views/Admin/HostelManagement/Rooms')
            },
            {
                path: "bedspaces",
                name: 'Manage Bed Spaces',
                component: () => import( /* webpackChunkName: "hostel-bed-spaces" */ '../components/Views/Admin/HostelManagement/BedSpaces')
            },
            ]
        },

        {
            path: "reservation-and-allocations",
            //name: 'Manage Reservations And Allocations',
            component: () => import( /* webpackChunkName: "bedspace-reservation-and-allocations" */ '../components/Views/Admin/HostelManagement/BedSpaceReservationsAndAllocations'),
            //props: true,
            children: [{
                path: "",
                name: 'Manage Bedspace Allocations',
                component: () => import( /* webpackChunkName: "manage-bed-space-allocations" */ '../components/Views/Admin/HostelManagement/Reservations')
            },
            {
                path: "allocations",
                name: 'Manage Allocations',
                component: () => import( /* webpackChunkName: "allocations" */ '../components/Views/Admin/HostelManagement/Allocations')
            },
            ]
        },

        {
            path: "hostel-balloting-schedule",
            name: 'Hostel Balloting Schedule',
            component: () => import( /* webpackChunkName: "hostel-balloting-schedule" */ '../components/Views/Admin/HostelManagement/HostelBallotingSchedule')
        },

        {
            path: "manual-reservation",
            name: 'Manual Reservation',
            component: () => import( /* webpackChunkName: "manual-hostel-reservation" */ '../components/Views/Admin/HostelManagement/ManualReservation')
        },

        {
            path: "manual-allocation",
            name: 'Manual Allocation',
            component: () => import( /* webpackChunkName: "manual-hostel-allocation" */ '../components/Views/Admin/HostelManagement/ManualAllocation')
        },

    ]
},

//-----------------------------------------routings for private institutions-------------------//
{
    path: '/applicant/:acronym?',
    name: 'Applicant',
    component: () => import(/* webpackChunkName: "ug-applicant-login" */ '../components/Views/Candidate/Undergraduate/PrivateInstitutions/Login'),
    meta: { guestUserOnly: true }
},
{
    path: '/ug/applicant/enroll',
    name: 'Applicant Enroll',
    component: () => import( /* webpackChunkName: "ug-applicant-enrollment" */ '../components/Views/Candidate/Undergraduate/PrivateInstitutions/Enroll'),
    meta: { guestUserOnly: true }
},
{
    path: '/ug/applicant/email/activate',
    name: 'Applicant Email Verification',
    component: () => import( /* webpackChunkName: "applicant-email-verification" */ '../components/Views/Candidate/Undergraduate/PrivateInstitutions/EmailVerification'),
    meta: { guestUserOnly: true }
},

{
    path: '/ug/applicant/email/activated',
    name: 'Applicant Email Verified',
    component: () => import( /* webpackChunkName: "candidate-activated" */ '../components/Views/Candidate/Undergraduate/PrivateInstitutions/CandidateAccountActivated'),
    props: true,
    meta: { guestUserOnly: true }
},
{
    path: '/direct-candidate',
    name: 'Private Candidate Application',
    component: () => import( /* webpackChunkName: 'direct-candidate-dashboard' */ '../components/Views/Candidate/Undergraduate/PrivateInstitutions/Dashboard'),
    meta: { authenticatedUserOnly: true },
    beforeEnter: checkCandidateRole,
    children: [

        {
            path: 'private/application',
            name: "Direct Candidate Application",
            component: () => import( /* webpackChunkName: 'direct-candidate-application' */ '../components/Views/Candidate/Undergraduate/PrivateInstitutions/Application'),
        },

        {
            path: 'utme/application',
            name: "UTME Candidate Application",
            component: () => import( /* webpackChunkName: 'utme-application' */ '../components/Views/Candidate/Undergraduate/UTMEApplication'),
        },

        {
            path: 'announcements',
            name: "Candidate's Announcements",
            component: () => import( /* webpackChunkName: 'candidate-announcements' */ '../components/Views/Candidate/Announcements'),
        },

        {
            path: 'admission/clearance-documents',
            name: "Candidate Clearance Documents",
            component: () => import( /* webpackChunkName: 'clearance-documents' */ '../components/Views/Candidate/Undergraduate/ClearanceDocuments'),
        },

        {
            path: 'bio-data',
            name: "Candidate Bio-data",
            component: () => import( /* webpackChunkName: 'candidate-biodata' */ '../components/Views/Candidate/Undergraduate/BioData'),
            // props: true
        },
        {
            path: 'results/o-level',
            name: "Candidate Olevels",
            component: () => import( /* webpackChunkName: 'candidate-olevels' */ '../components/Views/Candidate/Undergraduate/Olevels'),
            // props: true
        },
        {
            path: 'admission/status',
            name: "Private Candidate Admission Status",
            component: () => import( /* webpackChunkName: 'direct-candidate-admission-status' */ '../components/Views/Candidate/Undergraduate/AdmissionStatus'),
            // props: true
        },

        {
            path: 'admission/acceptance-fees',
            name: "private.candidate.payAdmissionAcceptanceFee",
            component: () => import( /* webpackChunkName: 'pay-admission-acceptance-fees-direct-candidate' */ '../components/Views/Candidate/Undergraduate/Fees/PayAdmissionAcceptanceFee'),
        },


        {
            path: 'printouts',
            name: "Candidate Application Printouts",
            component: () => import( /* webpackChunkName: 'admission-application-printouts' */ '../components/Views/Candidate/PrintOuts'),

        },

        {
            path: 'migrate-to-student',
            name: "Migrate candidate to student",
            component: () => import( /* webpackChunkName: 'migrate-candidate-to-student' */ '../components/Views/Candidate/Undergraduate/MigrateCandidateToStudent'),

        },

    ],
},

];

const router = new VueRouter({
    routes,
    mode: "history",
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.authenticatedUserOnly)) {
        // if user is logged in, allow access
        // else redirect to login
        AuthUser.authCheck()
            .then(() => {
                next();
            })
            .catch(() => {
                next("/login");
            });
        // next();
    } else if (to.matched.some((record) => record.meta.guestUserOnly)) {
        // if user is logged in, restrict access
        // only for guests
        AuthUser.authCheck()
            .then((response) => {
                let primaryRole = response.data.data.roles[0];
                let primaryRoleName =
                    primaryRole.type === "Admin" || primaryRole.name === "Staff"
                        ? "Admin"
                        : primaryRole.name;
                primaryRoleName = primaryRoleName.trim();

                let nextPath = "";
                switch (primaryRoleName) {
                    case "Admin":
                    case "Super Admin":
                        nextPath = "/admin"
                        break;
                    case "Lecturer":
                        nextPath = "/lecturer"
                        break;
                    case "Student":
                        nextPath = "/student"
                        break;
                    case "Candidate":
                        nextPath = "/candidate"
                        break;
                    default:
                        nextPath = "/"
                }
                next(nextPath);
            }).catch(() => {
                next();
            });
    } else {
        next(); // make sure to always call next()!
    }
});


function checkSuperAdminRole(to, from, next) {
    UserAPI.auth()
        .then(response => {
            // if (response.data.data.usertype === 'Admin') {
            if (roleNamesContain(response.data.data.roles, 'Admin')
                || roleNamesContain(response.data.data.roles, 'Super Admin')
                || hasAdminRole(response.data.data.roles)) {
                next();
            } else {
                next({
                    path: "/no-access",
                    query: { redirect: to.fullPath },
                });
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function checkSuperAdminOrDirectorRole(to, from, next) {
    UserAPI.auth()
        .then(response => {
            // if (response.data.data.usertype === 'Admin') {
            if (roleNamesContain(response.data.data.roles, 'Admin')
                || roleNamesContain(response.data.data.roles, 'Super Admin')
                || roleNamesContain(response.data.data.roles, 'Director')
                || hasAdminRole(response.data.data.roles)) {
                next();
            } else {
                next({
                    path: "/no-access",
                    query: { redirect: to.fullPath },
                });
            }
        })
        .catch((error) => {
            console.log(error);
        });
}


function checkLecturerRole(to, from, next) {
    UserAPI.auth()
        .then((response) => {
            if (roleNamesContain(response.data.data.roles, "Lecturer")) {
                next();
            } else {
                next({
                    path: "/no-access",
                    query: { redirect: to.fullPath },
                });
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function checkHODRole(to, from, next) {
    UserAPI.auth()
        .then(response => {
            if (roleNamesContain(response.data.data.roles, 'HOD')) {
                next();
            } else {
                next({
                    path: '/no-access',
                    query: { redirect: to.fullPath }
                });
            }
        })
        .catch(error => {
            console.log(error);
        });
}

function checkCordinatorRole(to, from, next) {
    UserAPI.auth()
        .then(response => {
            if (roleNamesContain(response.data.data.roles, 'Cordinator')) {
                next();
            } else {
                next({
                    path: '/no-access',
                    query: { redirect: to.fullPath }
                });
            }
        })
        .catch(error => {
            console.log(error);
        });
}

function checkDirectorRole(to, from, next) {
    UserAPI.auth()
        .then(response => {
            if (roleNamesContain(response.data.data.roles, 'Director')) {
                next();
            } else {
                next({
                    path: '/no-access',
                    query: { redirect: to.fullPath }
                });
            }
        })
        .catch(error => {
            console.log(error);
        });
}

function checkRole(to, from, next) {
    UserAPI.auth()
        .then(response => {
            if (roleNamesContain(response.data.data.roles, 'Dean of Faculty')) {
                next();
            }

            else if (roleNamesContain(response.data.data.roles, 'Deputy Dean')) {
                next();
            }
            else {
                next({
                    path: '/no-access',
                    query: { redirect: to.fullPath }
                });
            }
        })
        .catch(error => {
            console.log(error);
        });
}

function checkLevelAdviserRole(to, from, next) {
    UserAPI.auth()
        .then(response => {
            if (roleNamesContain(response.data.data.roles, 'Level Adviser')) {
                next();
            } else {
                next({
                    path: '/no-access',
                    query: { redirect: to.fullPath }
                });
            }
        })
        .catch(error => {
            console.log(error);
        });
}

function checkStudentRole(to, from, next) {
    UserAPI.auth()
        .then((response) => {
            if (roleNamesContain(response.data.data.roles, "Student")) {
                next();
            } else {
                next({
                    path: "/no-access",
                    query: { redirect: to.fullPath },
                });
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function checkCandidateRole(to, from, next) {
    UserAPI.auth()
        .then((response) => {
            if (roleNamesContain(response.data.data.roles, "Candidate")) {
                next();
            } else {
                next({
                    path: "/no-access",
                    query: { redirect: to.fullPath },
                });
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function checkAdmissionsDashboardPermission(to, from, next) {
    UserAPI.getMyRolesAndPermissions([])
        .then(response => {
            if (hasAllPermissionsTo(response.data.permissionNames, ['view admissions dashboard'])
                || response.data.permissionNames.find(permission => permission === 'clear admitted candidates')
                || response.data.roleNames.find(role => role === 'Admin')
                || response.data.roleNames.find(role => role === 'Super Admin')
            ) {
                next();
            } else {
                next({
                    path: "/no-access",
                    query: { redirect: to.fullPath },
                });
            }
        })
        .catch((error) => {
            console.log(error);
        });
}


function roleNamesContain(roleList, roleName) {
    return roleList.find(role => role.name === roleName) ? true : false;
}

function hasAdminRole(roleList) {
    return roleList.find(role => role.type === "Admin") ? true : false;
}

function hasAllPermissionsTo(userPermissions, requiredPermissions) {
    //function to check if all the elements of an array are present in another array
    if (0 === requiredPermissions.length) {
        return false;
    }
    return requiredPermissions.every(function (value) {
        return userPermissions.indexOf(value) >= 0;
    });
}

function enforceRequestParameter(to, from, next) {
    // Check if the "id" parameter is present in the route
    if (to.query.id) {
        next(); // Proceed to the route if the "id" parameter exists
    } else {
        // Redirect to the "Not Found" component if "id" is missing
        next({
            path: "/no-access",
            query: { redirect: to.fullPath },
        });
    }
}

export default router;
