import { defineStore } from "pinia";
import { useInstituteStore } from './instituteStore';

export const useUserStore = defineStore("user", {
  state: () => {
    return { 
      role_names: [],
      permission_names: [],
      auth_user:{},
      subdomain:''
    };
  },
  actions: {
    setRoleNames(role_name_list) {
      this.role_names = role_name_list;
    },

    setPermissionNames(permission_name_list) {
      this.permission_names = permission_name_list;
    },

    setUser(user) {
      this.auth_user = user;
    },

    setSubdomain(subdomain) {
      this.subdomain = subdomain;
    },

    is_institute_director(){
      const institute_id = useInstituteStore().instituteId();

      return this.role_names.includes('Director') 
      && this.auth_user.director_appointments && 
      this.auth_user.director_appointments.find(appointment => appointment.entity_id == institute_id && appointment.entity_type == 'Institute') 
      ? true : false;
    },

    can(permission){
      return this.role_names.includes('Super Admin') || this.is_institute_director() || this.permission_names.includes(permission);
    },
  },
  getters: {
    auth(state){
      return state.auth_user;
    },
    getRoles(state){
      return state.role_names;
    },
    getPermissions(state){
      return state.permission_names;
    },

    getSubdomain(state){
      return state.subdomain;
    },
  },
});

